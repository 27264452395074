import { useNavigate } from "react-router-dom";

import { Button, ButtonProps } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  ShingleResealContextState,
} from "pages/shingle-reseal/shingle-reseal-flow";

import styles from "./shingle-reseal.module.scss";

export type ShingleResealButtonsProps = {
  primaryType?: ButtonProps["type"];
  primaryText?: string;
  primaryPath?: string;
  primaryOnClick?: ButtonProps["onPress"];
  primaryDisabled?: boolean;
  primaryLoading?: boolean;
};

export const ShingleResealButtons = ({
  primaryType,
  primaryPath,
  primaryText,
  primaryOnClick,
  primaryDisabled,
  primaryLoading,
}: ShingleResealButtonsProps) => {
  const navigate = useNavigate();
  const { formNavigate, state } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);

  return (
    <div className={styles.buttonWrapper}>
      <Button
        appearance="tertiary"
        onPress={() => navigate(state.from ?? "/my/settings/home-protection")}
        data-testid="shingle-cancel"
      >
        Cancel
      </Button>
      <div className={styles.navButtonWrapper}>
        <Button
          appearance="secondary"
          onPress={() => formNavigate(-1)}
          data-testid="shingle-back"
        >
          Back
        </Button>
        <Button
          appearance="primary"
          type={primaryType}
          onPress={
            primaryOnClick ??
            (primaryPath ? () => formNavigate(primaryPath) : undefined)
          }
          disabled={primaryDisabled}
          loading={primaryLoading}
          data-testid="shingle-continue"
        >
          {primaryText ?? "Next"}
        </Button>
      </div>
    </div>
  );
};
