import { useFormikContext } from "formik";
import { ReactNode, useCallback, useState } from "react";

import { Card, Heading, Icon } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { PaymentCards } from "components/payment-card";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import OneIncLogo from "images/one-inc-logo.svg";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";

import { BankInfo } from "./bank-info";
import { CardInfo } from "./card-info";
import { ChooseDay } from "./choose-day";
import { ChooseMethod } from "./choose-method";
import styles from "./easypay-enroll-form.module.scss";
import { Review } from "./review";
import { WelcomeCard } from "./welcome-card";

export type EnrollmentStep =
  | "welcome"
  | "chooseMethod"
  | "choosePlan"
  | "chooseDay"
  | "cardInfo"
  | "bankInfo"
  | "review";

const stepMap: Record<EnrollmentStep, string> = {
  welcome: "Welcome",
  chooseMethod: "Payment method",
  choosePlan: "Payment plan",
  chooseDay: "Processing day",
  cardInfo: "Payment information",
  bankInfo: "Payment information",
  review: "Review",
};

type EasypayEnrollFormProps = {
  trackEasyPayEvent: (event: string, data: Record<string, string>) => void;
};

export const EasypayEnrollForm = ({
  trackEasyPayEvent,
}: EasypayEnrollFormProps) => {
  const [step, setStep] = useState<EnrollmentStep>("welcome");

  const {
    values: { draftDate, easypayMethod },
  } = useFormikContext<EasyPayFormValues>();

  let easypayMethodValue = "";
  if (easypayMethod) {
    easypayMethodValue = easypayMethod.includes("cc") ? "debit-credit" : "ach";
  }

  const handleSetStep = useCallback(
    (step: EnrollmentStep) => {
      setStep(step);
      trackEasyPayEvent(`EasyPay enrollment step - ${stepMap[step]}`, {
        processingDay: draftDate ?? "not set",
        easypayMethod: easypayMethodValue,
      });
    },
    [draftDate, easypayMethodValue, trackEasyPayEvent]
  );

  const Sidebar = () => {
    if (step === "choosePlan") {
      return <PlanCard />;
    } else if (step === "chooseMethod") {
      return <ProcessingFeeCard />;
    } else if (step === "cardInfo") {
      return (
        <Stack>
          <ProcessingFeeCard />
          <OneIncCard />
        </Stack>
      );
    } else if (step === "bankInfo") {
      return <OneIncCard />;
    } else {
      return <></>;
    }
  };

  const progressCompleted = {
    welcome: 10,
    choosePlan: 25,
    chooseDay: 40,
    chooseMethod: 60,
    bankInfo: 75,
    cardInfo: 75,
    review: 90,
  };

  return (
    <>
      <ProgressBar progress={progressCompleted[step]} />
      <MainLayout sidebar={<Sidebar />}>
        <Card>
          {step === "welcome" && <WelcomeCard setStep={handleSetStep} />}
          {step === "choosePlan" && <PaymentCards setStep={handleSetStep} />}
          {step === "chooseDay" && <ChooseDay setStep={handleSetStep} />}
          {step === "chooseMethod" && <ChooseMethod setStep={handleSetStep} />}
          {step === "cardInfo" && <CardInfo setStep={handleSetStep} />}
          {step === "bankInfo" && <BankInfo setStep={handleSetStep} />}
          {step === "review" && <Review setStep={handleSetStep} />}
        </Card>
      </MainLayout>
    </>
  );
};

type SidebarCardProps = {
  content: ReactNode;
  icon: ReactNode;
  headline: ReactNode;
};
const SidebarCard = ({ content, icon, headline }: SidebarCardProps) => (
  <Card>
    <Heading size="md" className={styles.iconHeader}>
      {icon}
      {headline}
    </Heading>
    {content}
  </Card>
);

const PlanCard = () => (
  <SidebarCard
    icon={<Icon name="Calendar" size="lg" />}
    headline="Payment schedule"
    content={
      <div className={styles.paymentSidebarContent}>
        <div>
          We offer several plans to manage your payment schedule. Your schedule
          may vary based on the current policy term and any previous payments.
        </div>
        <div>
          <Heading size="sm" className={styles.header}>
            1 payment:
          </Heading>
          One payment at the beginning of the term.
        </div>
        <div>
          <Heading size="sm" className={styles.header}>
            2 payments:
          </Heading>
          First payment at the beginning of the term, second payment halfway
          through the term (i.e. six months later).
        </div>
        <div>
          <Heading size="sm" className={styles.header}>
            4 payments:
          </Heading>
          First payment at the beginning of the term, additional payments
          quarterly (i.e. every three months).
        </div>
        <div>
          <Heading size="sm" className={styles.header}>
            10 payments:
          </Heading>
          First payment at the beginning of the term, additional payments every
          month for the next nine months.
        </div>
      </div>
    }
  />
);
const ProcessingFeeCard = () => (
  <SidebarCard
    icon={<Icon name="Dollar" size="lg" />}
    headline="Processing fees"
    content="Our payment processor charges a 2.99% fee on credit card payments. This fee does not go to SageSure. To avoid fees, use your bank account instead."
  />
);

const OneIncCard = () => (
  <SidebarCard
    icon={<Icon name="Shield" size="lg" />}
    headline="Security partner"
    content={
      <span className={styles.securityContent}>
        <OneIncLogo width={164} />
        SageSure partners with One Inc for secure automated payments. One Inc is
        a fully certified and compliant payment service provider.
      </span>
    }
  />
);
