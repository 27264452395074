import { useEffect } from "react";

import ClaimTracker from "@icg360/claim-tracker";
import { Card } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { ClaimsPageDataQuery } from "gql/__generated__/hooks";
import { trackEvent } from "utils";

function trackDetailsClick(expanded, computedClaim) {
  trackEvent("View Claims Details Clicked", {
    claimID: computedClaim.CarrierClaim
      ? computedClaim.CarrierClaim.Number
      : computedClaim.FNOLSubmission.SubmissionNumber,
    claimStatus: computedClaim.lastCompletedMilestone.name,
    claimOpenStatus: computedClaim.Status,
    clickType: expanded ? "expand" : "collapse",
    claimSubmissionDate: computedClaim.submissionDate,
  });
}

const sortClaimsBySubmissionDate = (
  claims: ClaimTrackerItemWithSubmissionDate[]
) =>
  claims.sort(
    (claimA, claimB) =>
      claimB.submissionDate.getTime() - claimA.submissionDate.getTime()
  );

type Claims = NonNullable<
  NonNullable<ClaimsPageDataQuery["userClaimsList"]>["ClaimsTrackerInfo"]
>;

type ClaimTrackerItemWithSubmissionDate = Claims[number] & {
  submissionDate: Date;
};

type ClaimsTrackerListProps = {
  claims: Claims;
  claimTrackedInternally: boolean;
};

export const ClaimActivity = ({
  claims,
  claimTrackedInternally,
}: ClaimsTrackerListProps) => {
  useEffect(() => {
    trackEvent("Claims Tracker: Displayed");
  }, []);

  const { activeClaims, pastClaims } = claims.reduce(
    (
      acc: {
        activeClaims: ClaimTrackerItemWithSubmissionDate[];
        pastClaims: ClaimTrackerItemWithSubmissionDate[];
      },
      origClaim
    ) => {
      const claim: ClaimTrackerItemWithSubmissionDate = {
        ...origClaim,
        submissionDate: origClaim.CarrierClaim
          ? new Date(origClaim.CarrierClaim.DateSubmitted)
          : new Date(origClaim?.FNOLSubmission?.DateReceived ?? ""),
      };

      if (claim.Status === "Open") {
        acc.activeClaims.push(claim);
      } else {
        acc.pastClaims.push(claim);
      }
      return acc;
    },
    {
      activeClaims: [],
      pastClaims: [],
    }
  );

  const sortedClaims = [
    ...sortClaimsBySubmissionDate([...activeClaims]),
    ...sortClaimsBySubmissionDate([...pastClaims]),
  ];

  return (
    <Card title="Activity">
      <Stack>
        {sortedClaims.map((claim) => {
          const claimNumber = claim.CarrierClaim
            ? claim.CarrierClaim.Number
            : claim?.FNOLSubmission?.SubmissionNumber ?? "";

          return (
            <ClaimTracker
              claim={claim}
              onDetailsToggle={trackDetailsClick}
              key={claimNumber}
              id={claimNumber}
              claimTrackedInternally={claimTrackedInternally}
            />
          );
        })}
      </Stack>
    </Card>
  );
};
