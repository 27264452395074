import { useCallback, useContext, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

import PolicyholderFnol from "@icg360/fnol-policyholder";

import { AuthAppContext } from "components/root/auth-app-provider";
import { ClaimConstantsLoaderData } from "components/root/router";
import { FullpageSpinner } from "components/shared/fullpage-spinner";
import { CONFIG } from "config";
import {
  PolicyHolderDetails,
  useExternalClaimSubmitQuery,
  useSubmitFnolMutation,
} from "gql/__generated__/hooks";
import { userClaimsListQuery } from "gql/queries";
import PageError from "pages/page-error";
import {
  KeystoneApiClient,
  isError,
  trackEvent,
  trackFNOLEvent,
  useFlags,
} from "utils";

const { fnolChannelSubmit } = new KeystoneApiClient(CONFIG.KEYSTONE_API_HREF);

const ExternalClaimSubmit = () => {
  const { retireProxy, universalLogin } = useFlags();
  const navigate = useNavigate();
  const { selectedPolicyId, userInfo } = useContext(AuthAppContext);
  const [pageError, setPageError] = useState<boolean>(false);
  const loaderData = useLoaderData() as ClaimConstantsLoaderData;
  const [policyHolderDetails, setPolicyHolderDetails] =
    useState<PolicyHolderDetails>();

  useEffect(() => {
    trackEvent("Claim Submit Form: Displayed");
  }, []);

  const [submitFnolMutation] = useSubmitFnolMutation({
    refetchQueries: (result) => {
      if (result?.data?.fnolChannelSubmission?.submissionNumber) {
        return [
          {
            query: userClaimsListQuery,
            variables: {
              policyID: selectedPolicyId,
            },
          },
        ];
      } else {
        return [];
      }
    },
  });

  const {
    loading,
    error: gqlError,
    data: policyDetails,
  } = useExternalClaimSubmitQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const doClaimConstantsQuery = useCallback(() => {
    const claimsConstants = loaderData?.claimsConstants;
    if (claimsConstants) {
      setPolicyHolderDetails(claimsConstants?.PolicyHolderDetails);
    }
  }, [loaderData?.claimsConstants]);

  useEffect(() => {
    if (gqlError) setPageError(true);

    if (retireProxy && universalLogin) {
      doClaimConstantsQuery();
    } else {
      setPolicyHolderDetails(
        policyDetails?.userClaimsConstants?.PolicyHolderDetails
      );
    }
  }, [
    doClaimConstantsQuery,
    gqlError,
    policyDetails?.userClaimsConstants?.PolicyHolderDetails,
    retireProxy,
    universalLogin,
  ]);

  if (loading) {
    return <FullpageSpinner />;
  }

  if (pageError) {
    return <PageError />;
  }

  const { userDetails, userPolicyProperties, userCarrierInfo } =
    policyDetails ?? {};

  const { propertyAddress, currentTerm } = userDetails ?? {};

  const policy = {
    policyId: selectedPolicyId,
    policyName: `${userCarrierInfo?.name} (${userPolicyProperties?.policyType})`,
    policyEffectiveDate: currentTerm?.effectiveDate,
    policyExpirationDate: currentTerm?.expirationDate,
    fullName: policyHolderDetails?.name ?? "",
    email: policyHolderDetails?.emailAddress ?? "",
    phoneNumber: policyHolderDetails?.phoneNumber ?? "",
    streetAddress: `${propertyAddress?.street1} ${propertyAddress?.street2}`,
    city: propertyAddress?.city ?? "",
    state: propertyAddress?.state ?? "",
    zip: propertyAddress?.zip ?? "",
    carrierGroup: userPolicyProperties?.carrierGroup ?? "",
    policyType: userPolicyProperties?.policyType ?? "",
  };

  const handleFNOLPostApi = async (event) => {
    const response = await fnolChannelSubmit(event);
    if (isError(response)) {
      setPageError(true);
    }
    return response;
  };

  const handleFNOLPost = async (event) => {
    const { data } = await submitFnolMutation({
      variables: {
        event,
      },
    });

    return data?.fnolChannelSubmission;
  };

  const handleFormExit = () => {
    navigate("/my/claims");
  };

  return (
    <PolicyholderFnol
      definitionLocation={CONFIG.FNOL_DEFINITIONS_URL}
      policy={policy}
      user={userInfo?.email ?? undefined}
      googleMapsKey={CONFIG.GOOGLE_MAPS_KEY}
      onPost={
        retireProxy && universalLogin ? handleFNOLPostApi : handleFNOLPost
      }
      onExit={handleFormExit}
      onEvent={(event) => {
        trackFNOLEvent(event);
      }}
    />
  );
};

export default ExternalClaimSubmit;
