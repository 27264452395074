import PropTypes from "prop-types";

import { Button } from "@icg360/design-system";

const ShowAllButton = ({
  shownNb,
  setShownNb,
  minimumShownNb,
  type,
  totalNb,
}) => (
  <>
    {totalNb > minimumShownNb && (
      <Button
        appearance="secondary"
        size="sm"
        onPress={setShownNb}
        data-testid="show-all-btn"
      >
        {shownNb === minimumShownNb
          ? `Show all ${type.toLowerCase()}`
          : "Show less"}
      </Button>
    )}
  </>
);

ShowAllButton.propTypes = {
  shownNb: PropTypes.number,
  setShownNb: PropTypes.func,
  minimumShownNb: PropTypes.number,
  type: PropTypes.string,
  totalNb: PropTypes.number,
};

export default ShowAllButton;
