import { Container } from "react-grid-system";

import { Heading, Spinner } from "@icg360/design-system";

import { LogoImageLink } from "components/shared/logo-link";

import styles from "./loading-page.module.scss";

export const LoadingPage = ({ title }: { title: string }) => (
  <>
    <Container>
      <header className={styles.header}>
        <LogoImageLink />
      </header>
    </Container>
    <div className={styles.loadingContent}>
      <Heading size="xl">{title}</Heading>
      <p className={styles.loadingText}>It will take a few moments</p>
      <Spinner />
    </div>
  </>
);
