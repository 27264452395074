import MediaQuery from "react-responsive";

import { Card, Link as DSLink, Heading, Hr, Span } from "@icg360/design-system";

import { screenSizes } from "consts";
import { FAQ, FAQCategory, faqCategories } from "utils";

import styles from "./faq-list.module.scss";
import { useFAQList } from "./hooks";

type FAQListProps = {
  displayedFAQs: FAQ[];
  openQuestion: string;
};

const FAQList = ({ displayedFAQs, openQuestion }: FAQListProps) => {
  const { toggleExpandedSection, toggleQuestion, expandedSections, faqShown } =
    useFAQList({
      displayedFAQs,
      openQuestion,
    });

  const listFAQSection = (faqs: FAQ[], sectionTitle?: FAQCategory) => (
    <>
      {sectionTitle && (
        <Heading size="md" className={styles.sectionTitle}>
          {sectionTitle}
        </Heading>
      )}
      <ul id={`${sectionTitle || "related"}-faqs`} className={styles.list}>
        {faqs.map((faq, idx) => {
          return (
            <li id={faq.id} key={faq.id + idx}>
              <DSLink onPress={() => toggleQuestion(faq.id, false)}>
                <span>{faq.question}</span>
              </DSLink>
            </li>
          );
        })}
      </ul>
    </>
  );

  // Used for mobile:
  const listFAQSectionTruncated = (faqs: FAQ[], sectionTitle: FAQCategory) => {
    const expandable = faqs.length > 2;
    const shownFAQs = expandedSections[sectionTitle] ? faqs : faqs.slice(0, 2);

    return (
      <>
        {listFAQSection(shownFAQs, sectionTitle)}
        {expandable && !expandedSections[sectionTitle] && (
          <DSLink
            onPress={() => toggleExpandedSection(true, sectionTitle)}
            className={styles.seeMore}
          >
            <Span size="xs" color="interactive">
              See more
            </Span>
          </DSLink>
        )}
      </>
    );
  };

  const listFAQs = (truncated: boolean) => {
    const listFunction = truncated ? listFAQSectionTruncated : listFAQSection;
    return (
      <ul>
        {Object.values(faqCategories).map((category, idx) => {
          const categoryFaqs = displayedFAQs.filter(
            (faq) => faq.category === category
          );
          return categoryFaqs.length ? (
            <li key={idx}>{listFunction(categoryFaqs, category)}</li>
          ) : null;
        })}
      </ul>
    );
  };

  const faqAnswer = (faq: FAQ) => {
    const relatedFaqs = displayedFAQs.filter(
      (f) => f.category === faq.category && f.id !== faq.id
    );
    return (
      <div className={styles.answer}>
        <DSLink onPress={() => toggleQuestion(faq.id, true)}>
          {`< Back to FAQ`}
        </DSLink>
        <span className={styles.boldQuestion}>{faq.question}</span>
        <faq.answer />
        <Hr />
        <Heading size="xs">Related Questions</Heading>
        {listFAQSection(relatedFaqs.slice(0, 3))}
      </div>
    );
  };

  const currentFaq = faqShown && displayedFAQs.find((f) => f.id === faqShown);

  return (
    <Card title="How can we help?">
      {faqShown ? (
        faqAnswer(currentFaq)
      ) : (
        <MediaQuery maxWidth={screenSizes.laptop}>
          {(matches) => listFAQs(matches)}
        </MediaQuery>
      )}
    </Card>
  );
};

export default FAQList;
