import { ReactElement, useContext } from "react";
import { Container } from "react-grid-system";

import { Alert, Card, Link as DSLink, Spinner } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainWithSideBarLayout } from "components/shared/main-with-sidebar-layout";
import { ThumbtackModalProvider } from "components/thumbtack-modal";

import styles from "./home-services.module.scss";
import { useThumbtackHomeCare } from "./hooks";
import { TaskCard } from "./task-card";
import { HomeServicesMessage } from "./types";

type PHContentProps = {
  message: HomeServicesMessage;
};

export const PHContent = ({ message }: PHContentProps) => {
  const { propertyAddress } = useContext(AuthAppContext);
  return (
    <ThumbtackModalProvider>
      <>
        <Container>
          <MSSLink to="/my/property" className={styles.backLink}>
            {"< Back to Property"}
          </MSSLink>
        </Container>
        <MainWithSideBarLayout
          main={
            <PolicyHolderContentCard
              zipCode={propertyAddress?.zip ?? ""}
              message={message}
            />
          }
          sidebar={
            <Stack>
              <InsuranceRepresentative />
              <FaqSidebar page="property" />
            </Stack>
          }
        />
      </>
    </ThumbtackModalProvider>
  );
};

type PolicyHolderContentCardProps = {
  zipCode: string;
  message: HomeServicesMessage;
};

const PolicyHolderContentCard = ({
  zipCode,
  message,
}: PolicyHolderContentCardProps) => {
  const { results, loading, error } = useThumbtackHomeCare(zipCode);

  let homeCareContent: ReactElement;

  if (error) {
    homeCareContent = (
      <Alert
        appearance="danger"
        title="Oops, something went wrong!"
        description={
          <>
            We are not able to show any results at this time. Please try again
            or contact customer service at{" "}
            <DSLink href="tel:8093288821">(809) 328-8821</DSLink>
          </>
        }
      />
    );
  } else if (loading) {
    homeCareContent = (
      <div className={styles.homeCareState}>
        <div className={styles.homeCareStateIcon}>
          <Spinner />
        </div>
        <p>
          <strong>We’re checking for property care information.</strong> This
          will take a few moments. Please do not close your browser or refresh
          the screen.
        </p>
      </div>
    );
  } else {
    homeCareContent = (
      <div>
        <p>
          Maintaining your home&apos;s health can help keep your premiums low
          for years to come. Here are some tips for keeping your property in
          good shape and local contractors for hire to help you do it.
        </p>
        <section className={styles.suggestedTaskList}>
          {results.map((task) => (
            <TaskCard zipCode={zipCode} task={task} key={task.categoryPK} />
          ))}
        </section>
      </div>
    );
  }

  return <Card title={message.content.title}>{homeCareContent}</Card>;
};
