import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import { Card, Link as DSLink } from "@icg360/design-system";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/common/table";
import { ShowAllButton } from "components/show-all-button";
import { CONFIG } from "config";
import { MINIMUM_DOCS_SHOWN } from "consts";
import { formatDate, getApiDatetime } from "utils";

import styles from "./all-policy-docs.module.scss";

const AllPolicyDocs = ({
  GetUserDocuments: { userDocuments },
  GetUserDetails: { userDetails },
}) => {
  const [showAllDocs, setShowAllDocs] = useState(false);
  let docs = [];

  if (userDetails && userDocuments) {
    const { grouped } = userDocuments;
    const { isCommercial } = userDetails;
    docs = grouped
      .flat(Infinity)
      .map(({ route, label, lastUpdated, templateId }) => {
        const lastUpdatedDate = lastUpdated;
        const hrefDate = getApiDatetime(route);
        const createdAt =
          hrefDate > lastUpdatedDate ? lastUpdatedDate : hrefDate;
        return {
          route,
          label,
          createdAt,
          templateId,
        };
      })
      .filter(
        ({ templateId }) =>
          !isCommercial ||
          ![
            "BOPRenewalNoticeSICSC",
            "BOPRenewalProposalSICSC",
            "BOPRenewalProposalSICLA",
            "BOPRenewalProposalSICNC",
          ].includes(templateId)
      )
      .sort((a, b) => b.createdAt - a.createdAt);
  }

  return (
    <Card title="All policy documents">
      {docs.length !== 0 ? (
        <div className={styles.allPolicyDocuments}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Date</TableHeader>
                <TableHeader>Document</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs
                .slice(0, showAllDocs ? docs.length : MINIMUM_DOCS_SHOWN)
                .map(({ label, createdAt, route }, idx) => (
                  <Fragment key={`${formatDate(createdAt)}-${idx}`}>
                    <TableRow>
                      <TableCell>{formatDate(createdAt)}</TableCell>
                      <TableCell>
                        <DSLink
                          rel="noopener noreferrer"
                          href={`${CONFIG.KEYSTONE_PROXY_HREF}${route}`}
                          target="_blank"
                        >
                          {label}
                        </DSLink>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
          <div className={styles.showAll}>
            <ShowAllButton
              type="Documents"
              shownNb={showAllDocs ? docs.length : MINIMUM_DOCS_SHOWN}
              setShownNb={() => setShowAllDocs(!showAllDocs)}
              minimumShownNb={MINIMUM_DOCS_SHOWN}
              totalNb={docs.length}
            />
          </div>
        </div>
      ) : (
        <div className={styles.allPolicyDocuments}>
          There are no policy documents available at this time.
        </div>
      )}
    </Card>
  );
};

AllPolicyDocs.propTypes = {
  GetUserDocuments: PropTypes.shape({
    userDocuments: PropTypes.shape({
      grouped: PropTypes.array,
    }),
  }),
  GetUserDetails: PropTypes.shape({
    userDetails: PropTypes.shape({
      isCommercial: PropTypes.bool,
    }),
  }),
};

export default AllPolicyDocs;
