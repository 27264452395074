import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./data-row.module.scss";

const commonPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const DataList = ({ children, className, gap = "md" }) => {
  return (
    <dl
      className={cx(
        styles.dataList,
        {
          [styles.gapXxs]: gap === "xxs",
          [styles.gapXs]: gap === "xs",
          [styles.gapSm]: gap === "sm",
          [styles.gapMd]: gap === "md",
          [styles.gapLg]: gap === "lg",
        },
        className
      )}
    >
      {children}
    </dl>
  );
};

DataList.propTypes = {
  ...commonPropTypes,
  gap: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg"]),
};

const DataRow = ({
  children,
  className,
  flex,
  mobileFlex,
  noWrap,
  spaceBetween,
}) => {
  return (
    <div
      className={cx(
        styles.dataRow,
        {
          [styles.flex]: flex,
          [styles.noWrap]: noWrap,
          [styles.mobileFlexColumn]:
            mobileFlex === "col" || mobileFlex === "column",
          [styles.flexColumn]: flex === "col" || flex === "column",
          [styles.spaceBetween]: spaceBetween,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

DataRow.propTypes = {
  ...commonPropTypes,
  flex: PropTypes.oneOf(["col", "column", "row"]),
  noWrap: PropTypes.bool,
  mobileFlex: PropTypes.oneOf(["col", "column", "row"]),
  spaceBetween: PropTypes.bool,
};

const InlineDataRow = ({ children, className }) => {
  return (
    <div className={cx(styles.dataRow, styles.inline, className)}>
      {children}
    </div>
  );
};

InlineDataRow.propTypes = commonPropTypes;

const DataTerm = ({ children, className }) => {
  return <dt className={cx(styles.dataTerm, className)}>{children}</dt>;
};

DataTerm.propTypes = commonPropTypes;

const DataDefinition = ({ children, className }) => {
  return <dd className={cx(styles.dataDefinition, className)}>{children}</dd>;
};

DataDefinition.propTypes = commonPropTypes;

export { DataRow, InlineDataRow, DataList, DataTerm, DataDefinition };
