import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, MarketingIcon } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  ShingleContextState,
} from "pages/shingle-repair-pilot/index";
import ShingleRepairLayout from "pages/shingle-repair-pilot/shingle-repair-layout";
import { getShingleLandingPath } from "pages/shingle-repair-pilot/shingle-repair-pilot-utils";
import { formatTwoLineAddress, trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

const PolicyConfirmation = () => {
  const navigate = useNavigate();

  const { state, formNavigate } =
    useMultiStepFormState<ShingleContextState>(ShingleContext);
  const { policyId, propertyAddress } = state ?? {};

  useEffect(() => {
    trackEvent("shinglesForm_step2_load");
  }, []);

  useEffect(() => {
    if (!policyId || !propertyAddress?.zip) {
      navigate("/shingle-repair-pilot");
    }
  }, [navigate, policyId, propertyAddress?.zip]);

  if (!policyId || !propertyAddress?.zip) {
    return null;
  }

  const { lineOne, lineTwo } = formatTwoLineAddress(propertyAddress);

  return (
    <ShingleRepairLayout
      title="Sign-up this property?"
      description="Sign-up is only available for the property associated with your policy. At the moment, we can't accommodate sign-up for a different address."
      stepNumber={2}
    >
      <div className={styles.policyAddressWrapper}>
        <div className={styles.houseIcon}>
          <MarketingIcon name="FullHouse" />
        </div>
        <div className={styles.address}>
          <div>{lineOne}</div>
          <div>{lineTwo}</div>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          appearance="tertiary"
          onPress={() => {
            trackEvent("shinglesForm_step2_CancelClicked");
            navigate(getShingleLandingPath(state));
          }}
        >
          Cancel
        </Button>
        <div className={styles.navButtonsWrapper}>
          <Button
            appearance="secondary"
            onPress={() => {
              trackEvent("shinglesForm_step2_PreviousClicked");
              navigate(-1);
            }}
          >
            Previous
          </Button>
          <Button
            onPress={() => {
              trackEvent("shinglesForm_step2_ContinueClicked");
              formNavigate("../contact-information");
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </ShingleRepairLayout>
  );
};

export default PolicyConfirmation;
