import { Button, Icon, Span } from "@icg360/design-system";

import styles from "./roof-proof-upload.module.scss";

export const FileThumbnails = ({
  files,
  removeFile,
}: {
  files: (File & { preview?: string })[];
  removeFile?: (file) => void;
}) => {
  return files?.length > 0 ? (
    <div className={styles.preparedFiles}>
      {files.map((file, idx) => (
        <div className={styles.previewContainer} key={idx}>
          {file.type === "application/pdf" ? (
            <div className={styles.filePreview}>
              <Icon name="FilePdf" />
              <Span size="micro">{file.name}</Span>
            </div>
          ) : (
            <img
              src={file.preview}
              className={styles.thumbnailPreview}
              alt="thumbnail preview"
            />
          )}
          {removeFile ? (
            <Button
              className={styles.thumbnailButtonDS}
              onPress={() => removeFile(file)}
              size="xs"
              icon="Delete"
              aria-label="Delete_item"
              data-testid="writeouts-remove-file-btn"
            />
          ) : null}
        </div>
      ))}
    </div>
  ) : null;
};
