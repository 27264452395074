import { Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";

import { Heading, SpotIcon, addToast } from "@icg360/design-system";
import { PAYMENT_COMPLETE, PAYMENT_ERROR } from "@icg360/payment-toolkit-v2";

import UnauthLayout from "components/shared/unauth-layout/index";
import { policyZipCodeSchema } from "consts";
import {
  acceptPaymentOnCancelledPolicy,
  logError,
  payControl,
  scrollToTop,
  trackEvent,
} from "utils";

import AltPaymentBanner from "./alt-payment-banner";
import { BillPayForm } from "./bill-pay-form";
import { usePaymentInfo } from "./hooks";

const ERROR_DURATION = 10000; // ten seconds

export const BillPay = () => {
  const { verify } = usePaymentInfo();

  const handleError = (error: string, isWarning = false) => {
    trackEvent("Error Displayed (Payment)", { error });
    addToast(error, {
      icon: true,
      error: !isWarning,
      warning: isWarning,
      duration: ERROR_DURATION,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const onPaymentComplete = (response) => {
      (document.getElementById("bill-payment") as HTMLFormElement)?.reset();
      trackEvent("Submit Express Payment", response);
    };

    const onPaymentError = (error) => {
      addToast(
        "There was an error while submitting. Please refresh your browser and try again.",
        { icon: true, error: true }
      );
      const errorMessage = error?.message ?? "Unknown Error";
      logError(`Payment Error (unauthenticated) ${errorMessage}`);
      trackEvent("Payment Error (unauthenticated)");
    };

    return () => {
      payControl.addEventListener(PAYMENT_COMPLETE, onPaymentComplete);
      payControl.addEventListener(PAYMENT_ERROR, onPaymentError);
    };
  }, []);

  const handleSubmit = async ({ policyNumber, zipCode }) => {
    trackEvent("Start Making Express Payment");

    const { data, error } = await verify(policyNumber, zipCode);

    if (error) {
      handleError(error);
      return;
    }

    const {
      policyId,
      quoteId,
      insightPolicyId,
      email,
      phone,
      accountBalance,
      minAmountDue,
      policyState,
      totalPremium,
      policyStateAttributes,
    } = data;

    const { reasonCode, effectiveDate } = policyStateAttributes ?? {};
    const cancelledButEligibleForRenewal = acceptPaymentOnCancelledPolicy(
      policyState,
      reasonCode?.value,
      effectiveDate,
      minAmountDue
    );

    if (!cancelledButEligibleForRenewal) {
      if (policyState !== "ACTIVEPOLICY") {
        trackEvent("Error Displayed (Payment)", {
          error: "not active",
        });
        handleError(
          "Looks like your policy is no longer active. Please contact your insurance representative for assistance.",
          true
        );
        return;
      } else if (accountBalance <= 0) {
        trackEvent("Error Displayed (Payment)", {
          error: "no balance",
        });
        handleError(
          "You do not have a balance due at this time. Thank you for keeping your account up to date!",
          true
        );
        return;
      }
    }

    const finalPayDate = moment().utc().add(30, "days").format("YYYY-MM-DD");
    const minAmountDueScheduled = minAmountDue < 1 ? 1 : minAmountDue;

    payControl.makePayment({
      minAmountDue: Number(minAmountDueScheduled),
      accountBalance: Number(
        cancelledButEligibleForRenewal ? totalPremium : accountBalance
      ),
      insightPolicyId,
      paymentApplicationClient: "2000",
      policyId,
      email,
      phone,
      quoteId,
      isScheduledPayEnabled: true,
      finalPayDate,
    });
  };

  return (
    <>
      <UnauthLayout title="Pay my bill | SageSure">
        <SpotIcon name="Bill" appearance="bold" />
        <Heading size="lg">Pay my bill</Heading>
        <div>
          To make a payment, you&apos;ll need your policy number, zip code, and
          payment information.
        </div>
        <Formik
          initialValues={{
            policyNumber: "",
            zipCode: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={policyZipCodeSchema}
        >
          <BillPayForm />
        </Formik>
      </UnauthLayout>
      <AltPaymentBanner />
    </>
  );
};
