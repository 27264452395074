import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Heading } from "@icg360/design-system";

import UnauthLayout from "components/shared/unauth-layout";
import { trackEvent } from "utils";

import styles from "./claims-help.module.scss";

export const ClaimsHelp = () => {
  const navigate = useNavigate();
  const handleClick = (event, to) => {
    trackEvent(event);
    navigate(to);
  };

  useEffect(() => {
    trackEvent("landingV2_claims_pageLoad");
  }, []);

  const claimsHelp = {
    buttons: [
      {
        name: "Submit a Claim",
        event: "landingV2_claims_hereToSubmitClicked",
        to: "/claims-help/submit-claim?to=/my/claims",
      },
      {
        name: "Track an existing claim",
        event: "landingV2_claims_hereToTrackClicked",
        to: "/claims-help/submit-claim?to=/my/claims",
      },
      {
        name: "Get claims help",
        event: "landingV2_claims_getClaimsHelpClicked",
        to: "/claims-help/get-help",
      },
    ],
  };

  return (
    <UnauthLayout size="lg" title="Claims help | SageSure">
      <Heading size="lg">What are you here to do today?</Heading>
      <div className={styles.claimsHelpButtons}>
        {claimsHelp.buttons.map((button) => (
          <Button
            appearance="secondary"
            key={button.name}
            onPress={() => handleClick(button.event, button.to)}
          >
            {button.name}
          </Button>
        ))}
      </div>
    </UnauthLayout>
  );
};
