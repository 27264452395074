import { Field, Form, useFormikContext } from "formik";

import { Button, Heading } from "@icg360/design-system";

import { CeremonyRegistrationSchema } from "components/ceremony-registration/ceremony-registration";
import { InputField } from "components/shared/form-fields";
import { PasswordValidationList } from "components/shared/password-validation-list";

import styles from "./ceremony-registration.module.scss";

export const CeremonyRegistrationForm = () => {
  const { dirty, isSubmitting, values, isValid } =
    useFormikContext<CeremonyRegistrationSchema>();

  return (
    <Form className={styles.formWrapper}>
      <Field
        name="policyNumber"
        title="Policy number"
        component={InputField}
        placeholder="ABC123456789"
      />
      <Field
        name="zipCode"
        type="tel"
        title="Zip code"
        component={InputField}
        placeholder="42518"
      />

      <Heading size="xs" className={styles.capitalizedHeader}>
        Tell us about yourself
      </Heading>

      <Field
        md={12}
        sm={12}
        xs={12}
        name="firstName"
        title="First name"
        component={InputField}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        name="lastName"
        title="Last name"
        component={InputField}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        name="email"
        type="email"
        title="Email"
        component={InputField}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        type="password"
        name="password"
        title="Password"
        component={InputField}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        type="password"
        name="passwordConfirmation"
        title="Confirm password"
        component={InputField}
      />

      <PasswordValidationList
        password={values["password"]}
        passwordConfirmation={values["passwordConfirmation"]}
      />
      <Button
        disabled={!isValid || !dirty || isSubmitting}
        type="submit"
        className={styles.registerButton}
        data-testid="ceremony-register-btn"
      >
        Register
      </Button>
    </Form>
  );
};
