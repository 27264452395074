import { Field, Form, useFormikContext } from "formik";

import { Alert, Modal } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";
import { PasswordValidationList } from "components/shared/password-validation-list";
import { ChangePasswordModalSchema } from "pages/settings/security/change-password/change-password-modal/index";

import styles from "./change-password-modal.module.scss";

const ChangePasswordModalForm = ({ show, handleClose }) => {
  const {
    dirty,
    isValid,
    isSubmitting,
    values,
    resetForm,
    status,
    submitForm,
  } = useFormikContext<ChangePasswordModalSchema>();

  const onHide = () => {
    handleClose();
    resetForm();
  };

  return (
    <Modal
      open={show}
      onClose={onHide}
      heading="Change password"
      primaryAction="Change"
      onPrimaryAction={submitForm}
      disablePrimary={!isValid || !dirty || isSubmitting}
      loadingPrimary={isSubmitting}
    >
      {status && (
        <Alert
          appearance="danger"
          title=""
          description={status}
          className={styles.alert}
        />
      )}
      <Form>
        <div className={styles.formWrapper}>
          <Field
            name="currentPassword"
            title="Current password"
            component={InputField}
            type="password"
          />
          <Field
            name="password"
            title="New password"
            component={InputField}
            type="password"
          />
          <Field
            name="passwordConfirmation"
            title="Confirm password"
            component={InputField}
            type="password"
          />
          <PasswordValidationList
            password={values["password"]}
            passwordConfirmation={values["passwordConfirmation"]}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModalForm;
