import { StrictSchema, morphism } from "morphism";

import { HomeServicesMessage } from "components/home-services/types";
import {
  type Billing,
  type CarrierInfo,
  type Claim,
  type ClaimList,
  type ClaimTrackerItem,
  type Details,
  type Documents,
  type EasyPayDetails,
  type InsuranceRep,
  type PolicyProperties,
  type PropertyProfileData,
  type Submission,
  type Transactions,
} from "gql/__generated__/hooks";

export type KeystoneUser = {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  userName: string;
  authProvider?: string;
};

export type KeystonePolicyInfo = {
  policyId: string;
  insightPolicyId: string;
  propertyAddress?: {
    street1?: string | null;
    street2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    zipPlus4?: string | null;
  } | null;
  isCommercial?: boolean | null;
  policyState?: string | null;
};

export type KeystoneClaimsData = {
  ClaimsInfo: Claim[] | null;
  ClaimsTrackerInfo: ClaimTrackerItem[] | null;
  PolicyNumber: string | null;
  SubmissionsInfo: Submission[] | null;
};

type UnderwritingReferral = {
  underwritingAction: string;
  resolved: boolean;
  underwritingCondition: {
    referralCode: string;
    referralText: string;
    category: string;
    actionStatement: string;
    issueStatements: string[];
    reasoning: string;
    proof: string;
    contractor: string;
    serviceProvider: number;
    thumbtackSearchTerm: string;
  };
  referralActionRequired: string;
  location: string;
  referralCategory: string;
  issues?: unknown[];
};

export type KeystonePolicyDetail = {
  keystonePolicyId: string;
  timestamp: string;
  quoteNumber: string;
  pxServerIndex: string;
  platformPolicyId: string;
  policyState: string;
  carrier: string;
  carrierId: string;
  carrierGroupId: string;
  isCommercial: boolean;
  pendingCancellationDate: string;
  birthDates: unknown[];
  pendingChanges: unknown[] | null;
  mortgageeIndex: number;
  addresses: {
    firstName: string;
    lastName: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    zipPlus4: string;
    addressType: string;
  }[];
  terms: {
    policyNumber: string;
    termIndex: number;
    policyType: string;
    effectiveDate: number;
    effectiveDatePolicyTerm: number;
    expirationDate: number;
    agencyLocationCode: string;
    agencyId: string;
    agentId: string;
    agencyName: string;
    agentEmail: string;
    agentName: string;
    agentPhone: string;
    agentStreetNumber: string;
    agentStreetName: string;
    agentCity: string;
    agentState: string;
    agentZipCode: string;
    productRef: unknown;
    acvLossSettlementDwelling: number;
    aopDeductibleAmount: number;
    additionalAmountsOfInsurance: number;
    allOtherPerilsDeductible: string;
    autoPolicyCarrier: string;
    autoPolicyNumber: string;
    coverageA: number;
    coverageALimit: string;
    coverageAPremium: string;
    coverageB: number;
    coverageBLimit: string;
    coverageBPremium: string;
    coverageC: number;
    coverageCLimit: string;
    coverageCPremium: string;
    coverageD: number;
    coverageDLimit: string;
    coverageDPremium: string;
    coverageE: number;
    coverageELimit: string;
    coverageEPremium: string;
    coverageEPerOccurrence: number;
    coverageEAggregate: number;
    coverageF: number;
    coverageFLimit: string;
    coverageFPremium: string;
    coverageL: number;
    coverageM: number;
    deductibleAmount: number;
    deductibleMinimum: number;
    deductiblePercent: string;
    deductibleType: string;
    policyBlanketDeductible: string;
    policyBlanketLimit: string;
    totalPremium: number;
    propertyDamageLiabilityDeductibleOption: string;
    propertyDamageLiabilityDeductiblePerClaim: string;
    propertyDamageLiabilityDeductiblePerOccurrence: string;
    hurricaneDeductible: string;
    hurricaneDeductibleAmount: number;
    windHailDeductible: string;
    payeeDisbursement: string;
    payor: string;
    distanceToCoast: number;
    latitude: number;
    longitude: number;
    squareFootUnderRoof: number;
    dbaName: string;
    numberOfLocations: number;
    numberOfBuildings: number;
    termLocationData: unknown[];
    windHailDeductibleAmount: number;
    moldLimit: string;
    identityFraudCoveragePremium: string;
    mechanicalBreakdownCoveragePremium: string;
    serviceLinePremium: string;
  }[];
  mortgagees: {
    name: string;
    loanNumber: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    zipPlus4: string;
    index: number;
  }[];
  accounting: {
    currentBalanceDue: number;
    currentPaymentDue: number;
    equityDateMillis: number;
    invoiceAmountCurrent: number;
    invoiceAmountNext: number;
    invoiceDateCurrentMillis: number;
    invoiceDateDueCurrentMillis: number;
    invoiceDateDueNextMillis: number;
    invoiceDateNextMillis: number;
    minimumPaymentDue?: number;
    outstandingBalanceDue: number;
    pastDueBalance: number;
    pastDueDateMillis: number;
    paymentAmountLast: number;
    paymentDateLastMillis: number;
    paymentsSinceInvoice: number;
    renewalsPaid: number;
    twoPayAmountDue: number;
    twoPayRemainingBalance: number;
    twoPayTotalBalance: number;
    fourPayAmountDue: number;
    fourPayRemainingBalance: number;
    fourPayTotalBalance: number;
    tenPayAmountDue: number;
    tenPayRemainingBalance: number;
    tenPayTotalBalance: number;
    fullPayAmountDue: number;
    fullPayRemainingBalance: number;
    fullPayTotalBalance: number;
    paymentPlan: {
      planType: string;
      upfrontPayment: number;
      recurringPayment: number;
      setupCharge: number;
      installmentCharge: number;
      installments: {
        number: number;
        dueDateMillis: number;
        invoiceDateMillis: number;
        amount: number;
        feesAndPremiums: number;
        charges: number;
      }[];
    };
    payee: {
      DataItems: {
        OpPayeeDisbursementAddressLine2: string;
        OpPayeeDisbursementAddressLine1: string;
        OpPayeeDisbursementCity: string;
        OpPayeeDisbursementState: string;
        OpPayeeDisbursement: string;
        OpPayeeDisbursementZip: string;
      };
    };
    payor: {
      DataItems: {
        OpPayorAddressLine2: string;
        OpPayorOtherState: string;
        OpPayorName: string;
        OpPayorAddressLine1: string;
        OpPayor: string;
        OpPayorOtherCity: string;
        OpPayorOtherAddressLine2: string;
        OpPayorOtherZip: string;
        OpPayorOtherName: string;
        OpPayorOtherAddressLine1: string;
        OpPayorState: string;
        OpPayorZip: string;
        OpPayorCity: string;
      };
    };
    ledger: {
      LineItems: {
        Type: string;
        Value: number;
        Timestamp: number;
        DataItems: {
          PaymentCreditCardType?: string;
          Payment1CreditCardTypeDataItems?: string;
          PaymentAccountNumber?: string;
          Payment1AccountNumber?: string;
          PaymentMethod?: string;
          Payment1Method?: string;
        };
        Memo: string;
        AppliedDate: number;
      }[];
    };
    easyPayAccount: EasyPayDetails | null;
  };
  relatedItems: {
    notes: unknown[];
  };
  documents: {
    label: string;
    location: string;
    lastUpdated: string;
    templateId: string;
    parentId: string;
    href: string;
  }[];
  insuredPreferences: {
    easyPayEnrollmentStatus: string | null;
    paperlessBillingEnrollmentStatus: string | null;
    opInsuredEmailAddress: string | null;
    opInsuredEmailAddressBilling: string | null;
    paperlessDocumentEnrollmentStatus: string | null;
    opInsuredEmailAddressDocuments: string | null;
    opInsuredContactFirstName: string | null;
    opInsuredContactLastName: string | null;
  };
  policyStateAttributes: {
    reasonCode: null;
    effectiveDate: null;
  };
  propertyProfileData: {
    constructionYearRoof: number;
    poolType: string;
    burglarAlarm: string;
    fireAlarm: string;
    trampoline: boolean;
    roofCoveringType: string;
    poolFence: string | null;
    poolFenceAboveGround: string | null;
    divingBoardOrSlide: string | null;
    poolCovering: string | null;
    immovablePoolLadder: string | null;
    unlockedPoolGate: string | null;
  };
  primaryInsured: {
    firstName: string;
    lastName: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
  };
  coApplicant?: {
    [key: string]: {
      firstName: string;
      lastName: string;
      middleName: string;
    };
  };
  inspection: {
    status: string;
    completed: boolean;
    completedDate: string | null;
    dispositionCodeEnum: string;
    dispositionCode: string | null;
    message: string;
    orderDate: string | null;
    surveyDate: string | null;
    underwritingUpdatedDate: string | null;
    previewUrl: string | null;
    vendorName: string;
    confirmationNumber: string | null;
    newBusinessInspectionWriteOuts: {
      vendorName: string;
      projectId: string;
      referrals: UnderwritingReferral[];
      images: unknown[];
      dueDate: string;
    } | null;
    renewalWriteOuts: {
      projectId: number;
      referrals: UnderwritingReferral[];
    } | null;
  };
  isEnrolledInLeakBot: boolean;
  isBookRoll: boolean;
};

export type KeystoneCarrierInfo = {
  CarrierName: string;
  CarrierNameShort: string;
  CarrierId: string;
  CarrierLogo: string;
  Email: string;
  Website: string;
  Phone: string;
  Hours: string;
  Fax: string;
};

export type KeystonePaymentInfo = {
  policyId: string;
  quoteId: string;
  insightPolicyId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  accountBalance: number;
  minAmountDue: number;
  policyState: string;
  totalPremium: number;
  invoiceAmountCurrent: number;
  invoiceAmountNext: number;
  policyStateAttributes: {
    reasonCode: {
      description: string;
      label: string;
      value: string;
    };
    effectiveDate: string;
  };
};

// Mapping schema: keys determine the shape of the output object.
// The string values are the location of the value in the input object.
// Example: { CarrierName: "Bill" } => { name: "Bill" }
const carrierInfoSchema = {
  name: "CarrierName",
  nameShort: "CarrierNameShort",
  id: "CarrierId",
  logo: "CarrierLogo",
  email: "Email",
  website: "Website",
  phone: "Phone",
  hours: "Hours",
  fax: "Fax",
};

export function carrierInfoMap(input: KeystoneCarrierInfo) {
  return morphism<StrictSchema<CarrierInfo, KeystoneCarrierInfo>>(
    carrierInfoSchema,
    input
  );
}

const claimsDataSchema = {
  ClaimsInfo: "claims.ClaimsInfo",
  ClaimsTrackerInfo: "claims.ClaimsTrackerInfo",
  PolicyNumber: "policyId",
  SubmissionsInfo: "claims.SubmissionsInfo",
};

export function claimsDataMap(input: KeystoneClaimsData) {
  return morphism<StrictSchema<ClaimList, KeystoneClaimsData>>(
    claimsDataSchema,
    input
  );
}

const policySchema = {
  userBilling: {
    accounting: {
      dueDate: (input: KeystonePolicyDetail) => {
        if (input.accounting.minimumPaymentDue === 0) {
          return "No Upcoming Payment";
        }
        return dateString(input.accounting.invoiceDateDueCurrentMillis);
      },
      minimumPaymentDue: numToString("accounting.minimumPaymentDue"),
      invoiceAmountCurrent: numToString("accounting.invoiceAmountCurrent"),
      outstandingBalanceDue: numToString("accounting.outstandingBalanceDue"),
      paymentPlan: {
        planType: "accounting.paymentPlan.planType",
      },
      easyPayAccount: "accounting.easyPayAccount",
    },
    pendingChanges: {
      ScheduledPayments: "pendingChanges.ScheduledPayments",
    },
    mortgagees: "mortgagees",
    billingAddress: (input: KeystonePolicyDetail) => {
      // If invoice payment and there's a mortgagee
      if (
        input.accounting.paymentPlan.planType.toLowerCase() === "invoice" &&
        input.mortgagees?.[0]
      ) {
        return {
          line1: input.mortgagees[0].name,
          line2: `${input.mortgagees[0].street1} ${input.mortgagees[0].street2}`,
          line3: `${input.mortgagees[0].city}, ${input.mortgagees[0].state} ${input.mortgagees[0].zip}`,
        };
      }

      // Or if there's a mailing address
      const mailAddr = input.addresses.find(
        (addr) => addr.addressType.toLowerCase() === "mailing"
      );
      if (mailAddr) {
        return {
          line1: `${mailAddr.firstName} ${mailAddr.lastName}`,
          line2: `${mailAddr.street1} ${mailAddr.street2}`,
          line3: `${mailAddr.city}, ${mailAddr.state} ${mailAddr.zip}`,
        };
      }

      return {
        line1: "",
        line2: "",
        line3: "",
      };
    },
    installments: (input: KeystonePolicyDetail) => {
      return input.accounting.paymentPlan.installments.map((installment) => ({
        ...installment,
        dueDateMillis: dateString(installment.dueDateMillis),
        invoiceDateMillis: dateString(installment.invoiceDateMillis),
      }));
    },
  },
  userDetails: {
    policyStatus: "policyState",
    propertyAddress: (input: KeystonePolicyDetail) => {
      return input.addresses.find(
        (addr) => addr.addressType.toLowerCase() === "property"
      );
    },
    mailingAddress: (input: KeystonePolicyDetail) => {
      return input.addresses.find(
        (addr) => addr.addressType.toLowerCase() === "mailing"
      );
    },
    additionalAddresses: (input: KeystonePolicyDetail) => {
      return input.addresses.filter(
        (addr) =>
          addr.addressType.toLowerCase() !== "mailing" &&
          addr.addressType.toLowerCase() !== "property" &&
          addr.addressType.toLowerCase() !== "property1"
      );
    },
    quoteNumber: "quoteNumber",
    currentTerm: {
      agencyLocationCode: "terms[0].agencyLocationCode",
      agentName: "terms[0].agentName",
      allOtherPerilsDeductible: "terms[0].allOtherPerilsDeductible",
      coverageA: numToString("terms[0].coverageA"),
      coverageB: numToString("terms[0].coverageB"),
      coverageC: numToString("terms[0].coverageC"),
      coverageD: numToString("terms[0].coverageD"),
      coverageE: numToString("terms[0].coverageE"),
      coverageEAggregate: numToString("terms[0].coverageEAggregate"),
      coverageEPerOccurrence: numToString("terms[0].coverageEPerOccurrence"),
      coverageF: numToString("terms[0].coverageF"),
      coverageL: numToString("terms[0].coverageL"),
      coverageM: numToString("terms[0].coverageM"),
      dbaName: "terms[0].dbaName",
      effectiveDate: (input: KeystonePolicyDetail) =>
        dateString(input.terms[0]?.effectiveDate),
      effectiveDatePolicyTerm: (input: KeystonePolicyDetail) =>
        dateString(input.terms[0]?.effectiveDatePolicyTerm),
      expirationDate: (input: KeystonePolicyDetail) =>
        dateString(input.terms[0]?.expirationDate),
      hurricaneDeductibleAmount: numToString(
        "terms[0].hurricaneDeductibleAmount"
      ),
      identityFraudCoveragePremium: "terms[0].identityFraudCoveragePremium",
      mechanicalBreakdownCoveragePremium:
        "terms[0].mechanicalBreakdownCoveragePremium",
      moldLimit: "terms[0].moldLimit",
      policyNumber: "terms[0].policyNumber",
      propertyDamageLiabilityDeductiblePerClaim:
        "terms[0].propertyDamageLiabilityDeductiblePerClaim",
      propertyDamageLiabilityDeductiblePerOccurrence:
        "terms[0].propertyDamageLiabilityDeductiblePerOccurrence",
      serviceLinePremium: "terms[0].serviceLinePremium",
      termLocationData: "terms[0].termLocationData",
      totalPremium: numToString("terms[0].totalPremium"),
      windHailDeductible: "terms[0].windHailDeductible",
    },
    keystonePolicyId: "keystonePolicyId",
    insuredPreferences: "insuredPreferences",
    primaryInsured: "primaryInsured",
    coApplicant: (input: KeystonePolicyDetail) => {
      return Object.keys(input.coApplicant ?? {})
        .map((key) => ({
          firstName: input.coApplicant?.[key].firstName ?? "",
          lastName: input.coApplicant?.[key].lastName ?? "",
        }))
        .filter((coApp) => !!coApp.firstName && !!coApp.lastName);
    },
    isEnrolledInLeakBot: "isEnrolledInLeakBot",
    isBookRoll: "isBookRoll",
    isCommercial: "isCommercial",
    policyStateAttributes: "policyStateAttributes",
    mortgageeIndex: (input: KeystonePolicyDetail) => {
      // pretty sure this value will never come back from the api
      // keeping it here to align with keystone proxy
      return input.mortgageeIndex ?? 1;
    },
  },
  userDocuments: (input: KeystonePolicyDetail) => {
    const POLICY_PACKAGES = "Policy Packages";
    const POLICY_INVOICING = "Policy Invoicing";
    const POLICY_ENDORSEMENTS = "Policy Endorsements";
    const POLICY_ENROLLMENT = "Policy Enrollment";
    const POLICY_CANCELLATION = "Policy Cancellation";
    const GENERAL_DOCUMENTS = "General Documents";
    const docGroups = {
      enrollment: POLICY_ENROLLMENT,
      authorization: POLICY_ENROLLMENT,
      application: POLICY_ENROLLMENT,
      sheet: POLICY_ENROLLMENT,
      proof: POLICY_ENROLLMENT,
      letter$: POLICY_ENROLLMENT,
      payment$: POLICY_ENROLLMENT,
      package: POLICY_PACKAGES,
      newbusiness: POLICY_PACKAGES,
      reinstate: POLICY_PACKAGES,
      renew: POLICY_PACKAGES,
      declaration: POLICY_PACKAGES,
      declination: POLICY_PACKAGES,
      invoice$: POLICY_INVOICING,
      endorse: POLICY_ENDORSEMENTS,
      nonrenew: POLICY_CANCELLATION,
      cancel: POLICY_CANCELLATION,
    };
    const docIndices = {
      [POLICY_PACKAGES]: 1,
      [POLICY_INVOICING]: 2,
      [POLICY_ENDORSEMENTS]: 3,
      [POLICY_ENROLLMENT]: 4,
      [POLICY_CANCELLATION]: 5,
      [GENERAL_DOCUMENTS]: 6,
    };
    // Sort docs by lastUpdated, then add group labels, indices, and routes
    const sorted = input.documents
      .slice()
      .sort((a, b) => {
        if (a.lastUpdated > b.lastUpdated) {
          return -1;
        }
        if (a.lastUpdated < b.lastUpdated) {
          return 1;
        }
        return 0;
      })
      .map((doc) => {
        const output = {
          ...doc,
          lastUpdated: doc.lastUpdated?.toString(),
          group: GENERAL_DOCUMENTS,
          index: docIndices[GENERAL_DOCUMENTS],
          route: `/api/docs?docPath=${doc.parentId}/${doc.location}`,
        };
        const id = doc.templateId || doc.label;
        // This finds the LAST match to match proxy's behavior
        const groupKey = Object.keys(docGroups)
          .filter((k) => new RegExp(k, "i").test(id))
          ?.pop();
        if (groupKey) {
          output.group = docGroups[groupKey];
        }

        if (docIndices[output.group]) {
          output.index = docIndices[output.group];
        }
        return output;
      });

    const grouped = Object.values(
      sorted.reduce<Record<number, typeof sorted>>((output, doc) => {
        if (!output[doc.index]) {
          output[doc.index] = [doc];
        } else {
          output[doc.index].push(doc);
        }
        return output;
      }, {})
    );
    const latest = {
      declaration: sorted.find((doc) => /declaration/i.test(doc.templateId)),
      invoice: sorted.find((doc) => /invoice$/i.test(doc.templateId)),
    };
    return { grouped, latest };
  },
  userPolicyProperties: {
    agencyLocationCode: "terms[0].agencyLocationCode",
    carrierGroup: "carrierGroupId",
    policyState: (input: KeystonePolicyDetail) => {
      const address = input.addresses.find(
        (addr) => addr.addressType.toLowerCase() === "property"
      );
      return address?.state;
    },
    policyType: "terms[0].policyType",
  },
  userInsuranceRep: {
    agencyName: "terms[0].agencyName",
    agentCity: "terms[0].agentCity",
    agentEmail: "terms[0].agentEmail",
    agentPhone: "terms[0].agentPhone",
    agentState: "terms[0].agentState",
    agentStreetName: "terms[0].agentStreetName",
    agentStreetNumber: "terms[0].agentStreetNumber",
    agentZipCode: "terms[0].agentZipCode",
  },
  userBillingAddress: (input: KeystonePolicyDetail) =>
    input.insuredPreferences.opInsuredEmailAddressBilling ||
    input.insuredPreferences.opInsuredEmailAddress,
  userTransactions: (input: KeystonePolicyDetail) => {
    const transactions = input.accounting?.ledger?.LineItems?.map(
      ({ Memo, Timestamp, Value, Type, DataItems }) => {
        let capitalizedType = Type.toLowerCase();
        capitalizedType =
          capitalizedType.charAt(0).toUpperCase() +
          capitalizedType.substring(1);
        const description = Memo && Memo.trim() !== "" ? Memo : capitalizedType;

        return {
          description,
          type: Type,
          amount: `${Value}`,
          date: dateString(Timestamp),
          creditCardType:
            DataItems.PaymentCreditCardType ||
            DataItems.Payment1CreditCardTypeDataItems,
          accountNumber:
            DataItems.PaymentAccountNumber || DataItems.Payment1AccountNumber,
          method: DataItems.PaymentMethod || DataItems.Payment1Method,
        };
      }
    ).sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });

    const payments = transactions.filter(
      ({ description }) => description.toLowerCase().indexOf("payment") !== -1
    );
    return {
      lastPaymentDate: payments.length > 0 ? payments[0].date : undefined,
      latestPayment:
        payments.length > 0 ? payments[0].amount : "No Payment History",
      transactions,
    };
  },
  userPropertyProfileData: "propertyProfileData",
  paperlessEnrollmentStatus: (input: KeystonePolicyDetail) => {
    switch (input.insuredPreferences?.paperlessBillingEnrollmentStatus) {
      case "100":
        return "completed";
      case "150":
        return "pending";
      case "200":
        return "not enrolled";
      default:
        return "not enrolled";
    }
  },
};

export type PolicyDetail = {
  userBilling: Billing;
  userDetails: Details;
  userDocuments: Documents;
  userPolicyProperties: PolicyProperties;
  userInsuranceRep: InsuranceRep;
  userBillingAddress: string | null;
  userTransactions: Transactions;
  userPropertyProfileData: PropertyProfileData;
  paperlessEnrollmentStatus: string;
};

export function policyDetailMap(input: KeystonePolicyDetail) {
  return morphism<StrictSchema<PolicyDetail, KeystonePolicyDetail>>(
    policySchema,
    input
  );
}

function dateString(input: number | string) {
  return new Date(input).toISOString().slice(0, 10);
}

// converts a string found at the morphism path into an integer
function numToString(path: string) {
  return {
    path,
    fn: (val) => (typeof val === "number" ? val.toString() : val),
  };
}

export type OfferStatusCode =
  | "SENT"
  | "OFFERED"
  | "ENROLLED"
  | "DECLINED"
  | "EXPIRED"
  | "ELIGIBLE"
  | "INELIGIBLE";

export type KeystoneOfferEligibility = {
  featured: boolean;
  insightPolicyId: string;
  offerId: string | null;
  offerStatusCode: OfferStatusCode;
  offerUrl: string;
  offeringId: string;
  offeringInternalName: string;
  offeringMarketingName: string;
  additionalDetails: Record<string, string>[];
};

export type KeystoneHomeServicesMessages = {
  messages: HomeServicesMessage[];
};
