import { array, shape, string } from "prop-types";

export const userBillingProp = {
  userBilling: shape({
    accounting: shape({
      minimumPaymentDue: string.isRequired,
      outstandingBalanceDue: string.isRequired,
      invoiceAmountCurrent: string.isRequired,
      paymentPlan: shape({ planType: string.isRequired }),
      easyPayAccount: shape({ lastFourDigits: string, paymentMethod: string }),
    }),
    mortgagees: array.isRequired,
  }),
};

export const latestDocProp = {
  route: string.isRequired,
  label: string.isRequired,
  group: string.isRequired,
};

export const userDocumentsProp = {
  userDocuments: shape({
    grouped: array.isRequired,
    latest: shape({
      declaration: shape(latestDocProp),
      invoice: shape(latestDocProp),
    }),
  }),
};
