import cx from "classnames";
import { Field, useFormikContext } from "formik";
import { useContext } from "react";

import { Button, Heading } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { PAYMENT_PLANS } from "consts";
import {
  PolicyPaymentPlansQuery,
  usePolicyPaymentPlansQuery,
} from "gql/__generated__/hooks";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";
import { getFrequencyName } from "utils";

import styles from "./payment-card.module.scss";

type PaymentCardsProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const PaymentCards = ({ setStep }: PaymentCardsProps) => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { data } = usePolicyPaymentPlansQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });
  const { policyPaymentPlans } = data ?? { policyPaymentPlans: [] };

  const { values, setFieldValue } = useFormikContext<EasyPayFormValues>();

  return (
    <div className={styles.wrapper}>
      <Heading size="lg">Choose a payment plan</Heading>
      <p>
        Your payment schedule and amounts are based on your policy term and any
        previous payments. We&apos;ll email your next payment date and amount
        when you finish signing up.
      </p>
      <div className={styles.paymentCards} id="paymentFrequency">
        {policyPaymentPlans
          .filter((paymentPlan) => {
            const method = values.easypayMethod ?? values.paymentMethod;
            return paymentPlan?.billingMethod === method.toLowerCase();
          })
          .sort(
            (a, b) => Number(a?.numberOfPayments) - Number(b?.numberOfPayments)
          )
          .map((plan) => (
            <Field
              key={plan?.planId}
              name="paymentFrequency"
              component={PaymentPlanCard}
              id={getFrequencyName(plan?.numberOfPayments)}
              plan={plan}
              selected={
                values.paymentFrequency ===
                getFrequencyName(plan?.numberOfPayments)
              }
              onChange={() => {
                setFieldValue(
                  "paymentFrequency",
                  getFrequencyName(plan?.numberOfPayments)
                );
              }}
            />
          ))}
      </div>
      <div className={styles.navigation}>
        <Button appearance="tertiary" onPress={() => setStep("welcome")}>
          Go back
        </Button>
        <Button
          onPress={() => setStep("chooseDay")}
          data-testid="easypay-enroll-continue-btn"
          disabled={!policyPaymentPlans.length || !values.paymentFrequency}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

type PaymentPlanCardProps = {
  plan: PolicyPaymentPlansQuery["policyPaymentPlans"][number];
  selected: boolean;
  onChange: () => void;
};
const PaymentPlanCard = ({
  onChange,
  selected,
  plan,
}: PaymentPlanCardProps) => {
  const displayedPlan = PAYMENT_PLANS.find(
    (p) => plan?.numberOfPayments === p.count
  );

  return (
    <button
      className={cx(styles.paymentCard, selected && styles.highlighted)}
      onClick={onChange}
    >
      <div className={styles.leftSide}>
        <Heading size="lg" className={styles.count}>
          {plan?.numberOfPayments}
        </Heading>
        <div className={styles.paymentsPer}>
          <div className={styles.payments}>
            {`payment${plan?.numberOfPayments === "1" ? "" : "s"}`}
          </div>
          <div>per policy term</div>
        </div>
      </div>

      <div className={styles.rightSide}>
        <div>
          {displayedPlan?.downPayment === "0"
            ? "at the beginning of the term"
            : `with a ${displayedPlan?.downPayment}% initial payment`}
        </div>
      </div>
    </button>
  );
};
