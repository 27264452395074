import { PropsWithChildren, useEffect, useState } from "react";
import { UnmountClosed } from "react-collapse";

import { Alert, Card, Link as DSLink, Span } from "@icg360/design-system";

import { RegularContentLoader } from "components/loader";
import { PolicySummary } from "components/shared/policy-summary";
import {
  ContactSettingsDataQuery,
  useContactItemDataQuery,
} from "gql/__generated__/hooks";
import SettingsPolicy from "pages/settings/common/settings-policy";
import { EditContactAddressButtonFormik } from "pages/settings/contact/contact-item/edit-contact-address-button-formik";
import { EditContactEmailButton } from "pages/settings/contact/contact-item/edit-contact-email-button";
import { EditContactPhoneButton } from "pages/settings/contact/contact-item/edit-contact-phone-button";
import { formatAddress, getPaperlessEnrollment, useFlags } from "utils";

import styles from "./contact-item.module.scss";
import { usePolicyHolderPhone, useUpdateForm } from "./hooks";
import UpdateEmail from "./update-email";
import UpdateMailingAddress from "./update-mailing-address";
import UpdatePhoneNumber from "./update-phone-number";

type ContactItemProps = {
  policyDetails: NonNullable<ContactSettingsDataQuery["userPolicies"]>[number];
  userEmail: string;
};

const ContactItem = ({ policyDetails, userEmail }: ContactItemProps) => {
  const { policyId, propertyAddress, isCommercial } = policyDetails;
  const { phoneLoading, policyHolderPhone, refreshPhone } =
    usePolicyHolderPhone(policyId);
  const { data: contactItemData, loading } = useContactItemDataQuery({
    variables: {
      policyID: policyId ?? "",
    },
  });

  const { contactUpdateInModal } = useFlags();

  if (!contactUpdateInModal && (phoneLoading || loading || !policyId)) {
    return <RegularContentLoader />;
  }

  const { userDetails, userBilling, userPolicyProperties } =
    contactItemData ?? {};

  const { isEnrolledBilling, isEnrolledDocuments } =
    userBilling && userDetails
      ? getPaperlessEnrollment(userBilling, userDetails)
      : { isEnrolledBilling: false, isEnrolledDocuments: false };

  // This is gross.
  // Remove this when we allow editing Vave/Markel contact information
  // We can remove `userPolicyProperties from the contactItemData query as well
  const NON_EDITING_CARRIERS = ["vave", "markel"];
  const allowEditing = !NON_EDITING_CARRIERS.includes(
    userPolicyProperties?.carrierGroup?.toLowerCase() ?? ""
  );

  return contactUpdateInModal ? (
    <SettingsPolicy
      isCommercial={!!isCommercial}
      address={propertyAddress}
      detailItems={[
        { label: "Policy number:", value: policyId },
        {
          label: "Insured email:",
          value: (
            <div className={styles.info}>
              {userDetails?.primaryInsured?.emailAddress}
              {allowEditing ? (
                <EditContactEmailButton
                  policyId={policyId}
                  userEmail={userEmail}
                  isEnrolledBilling={isEnrolledBilling}
                  isEnrolledDocuments={isEnrolledDocuments}
                />
              ) : null}
            </div>
          ),
        },
        {
          label: "Phone:",
          value: (
            <div className={styles.info}>
              {policyHolderPhone}
              {allowEditing ? (
                <EditContactPhoneButton
                  policyId={policyId}
                  userEmail={userEmail}
                  onSuccess={refreshPhone}
                />
              ) : null}
            </div>
          ),
        },
        {
          label: "Mailing address:",
          value: (
            <div className={styles.info}>
              {userDetails?.mailingAddress
                ? formatAddress(userDetails.mailingAddress, true)
                : ""}
              {allowEditing ? (
                <EditContactAddressButtonFormik
                  policyId={policyId}
                  userEmail={userEmail}
                />
              ) : null}
            </div>
          ),
        },
      ]}
      loading={phoneLoading || loading || !policyId}
    />
  ) : (
    <PolicySummary
      address={propertyAddress}
      policyId={policyId}
      isCommercial={!!isCommercial}
    >
      <EmailSection
        policyId={policyId}
        userEmail={userEmail}
        emailAddress={userDetails?.primaryInsured?.emailAddress ?? ""}
        isEnrolledBilling={isEnrolledBilling}
        isEnrolledDocuments={isEnrolledDocuments}
        allowEditing={allowEditing}
      />
      <PhoneSection
        policyId={policyId}
        userEmail={userEmail}
        phoneNumber={policyHolderPhone ?? ""}
        allowEditing={allowEditing}
      />
      <AddressSection
        policyId={policyId}
        userEmail={userEmail}
        address={
          userDetails?.mailingAddress
            ? formatAddress(userDetails.mailingAddress, true)
            : ""
        }
        isCommercial={!!isCommercial}
        allowEditing={allowEditing}
      />
    </PolicySummary>
  );
};

export default ContactItem;

type InfoItemProps = PropsWithChildren<{
  label: string;
  policyId: string;
  value: string;
  allowEditing?: boolean;
  errorMessage?: string | null;
  showForm: boolean;
  handleShowForm: () => void;
  handleErrorDismiss: () => void;
}>;

const InfoItem = ({
  label,
  value,
  allowEditing = false,
  policyId,
  children,
  errorMessage,
  showForm,
  handleShowForm,
  handleErrorDismiss,
}: InfoItemProps) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <div className={styles.info}>
        <Span bold>{label}</Span>
        <div className={styles.infoValue}>{value}</div>
        {allowEditing && !showForm ? (
          <DSLink
            data-testid={`edit-${policyId}-${label.substr(0, 3)}`}
            onPress={handleShowForm}
          >
            Edit
          </DSLink>
        ) : null}
      </div>
      <UnmountClosed isOpened={showForm}>
        <Card className={styles.editCard}>
          <>
            {errorMessage ? (
              <div className={styles.errorAlertWrapper}>
                <Alert
                  appearance="danger"
                  title=""
                  description={errorMessage}
                  dismissable
                  onDismiss={handleErrorDismiss}
                />
              </div>
            ) : null}
            {children}
          </>
        </Card>
      </UnmountClosed>
    </>
  );
};

type EmailSectionProps = {
  policyId: string;
  emailAddress: string;
  userEmail: string;
  isEnrolledBilling: boolean;
  isEnrolledDocuments: boolean;
  allowEditing: boolean;
};

const EmailSection = ({
  policyId,
  userEmail,
  emailAddress,
  isEnrolledBilling,
  isEnrolledDocuments,
  allowEditing,
}: EmailSectionProps) => {
  const {
    showForm,
    errorMessage,
    handleShowForm,
    handleCancelForm,
    handleContactInfoUpdateSuccess,
    handleContactInfoUpdateError,
    handleErrorDismiss,
  } = useUpdateForm("email");
  return (
    <InfoItem
      label="Insured email:"
      policyId={policyId}
      value={emailAddress}
      showForm={showForm}
      errorMessage={errorMessage}
      handleShowForm={handleShowForm}
      allowEditing={allowEditing}
      handleErrorDismiss={handleErrorDismiss}
    >
      <UpdateEmail
        policyId={policyId}
        userEmail={userEmail}
        onContactInfoUpdateSuccess={handleContactInfoUpdateSuccess}
        onContactInfoUpdateError={handleContactInfoUpdateError}
        enrolledBilling={isEnrolledBilling}
        enrolledDocuments={isEnrolledDocuments}
        onCancelForm={handleCancelForm}
      />
    </InfoItem>
  );
};

type PhoneSectionProps = {
  policyId: string;
  userEmail: string;
  phoneNumber: string;
  allowEditing: boolean;
};

const PhoneSection = ({
  policyId,
  userEmail,
  phoneNumber,
  allowEditing,
}: PhoneSectionProps) => {
  const {
    showForm,
    errorMessage,
    handleShowForm,
    handleCancelForm,
    handleContactInfoUpdateSuccess,
    handleContactInfoUpdateError,
    handleErrorDismiss,
  } = useUpdateForm("phone");
  const [phone, setPhone] = useState(phoneNumber);

  useEffect(() => {
    setPhone(phoneNumber);
  }, [phoneNumber]);

  return (
    <InfoItem
      label="Phone:"
      policyId={policyId}
      value={phone}
      showForm={showForm}
      errorMessage={errorMessage}
      handleShowForm={handleShowForm}
      allowEditing={allowEditing}
      handleErrorDismiss={handleErrorDismiss}
    >
      <UpdatePhoneNumber
        policyId={policyId}
        userEmail={userEmail}
        onContactInfoUpdateSuccess={handleContactInfoUpdateSuccess}
        onContactInfoUpdateError={handleContactInfoUpdateError}
        onCancelForm={handleCancelForm}
        updatePhoneDisplay={setPhone}
      />
    </InfoItem>
  );
};

type AddressSectionProps = {
  policyId: string;
  userEmail: string;
  address: string;
  isCommercial: boolean;
  allowEditing: boolean;
};

const AddressSection = ({
  policyId,
  userEmail,
  address,
  isCommercial,
  allowEditing,
}: AddressSectionProps) => {
  const {
    showForm,
    errorMessage,
    handleShowForm,
    handleCancelForm,
    handleContactInfoUpdateSuccess,
    handleContactInfoUpdateError,
    handleErrorDismiss,
  } = useUpdateForm("address");
  return (
    <InfoItem
      label="Mailing address:"
      policyId={policyId}
      value={address}
      showForm={showForm}
      errorMessage={errorMessage}
      handleShowForm={handleShowForm}
      allowEditing={allowEditing && !isCommercial}
      handleErrorDismiss={handleErrorDismiss}
    >
      <UpdateMailingAddress
        policyId={policyId}
        userEmail={userEmail}
        onContactInfoUpdateSuccess={handleContactInfoUpdateSuccess}
        onContactInfoUpdateError={handleContactInfoUpdateError}
        onFormCancel={handleCancelForm}
      />
    </InfoItem>
  );
};
