import { useContext } from "react";

import { Badge, Card, Heading } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { PAYMENT_METHOD_CODE } from "consts";
import { BillingPageDataQuery } from "gql/__generated__/hooks";
import PaymentPlanMortgagee from "pages/billing/billing/payment-plan-sidebar/payment-plan-mortgagee";
import PaymentPlanPolicyholderPayer from "pages/billing/billing/payment-plan-sidebar/payment-plan-policyholder-payer";
import { getPaymentMethodDetails } from "utils";

import styles from "./payment-plan-sidebar.module.scss";

type PaymentPlanSidebarProps = {
  userBillingEmailAddress?: string | null;
  easyPayEnrollmentStatus?: string | null;
  policyPaymentPlans?: BillingPageDataQuery["policyPaymentPlans"];
};

export const PaymentPlanSidebar = ({
  userBillingEmailAddress,
  policyPaymentPlans,
}: PaymentPlanSidebarProps) => {
  let paymentMethod, usingEasyPay, usingMortgagee, usingDirectBill;
  const { userBilling } = useContext(AuthAppContext);
  const { planType } = userBilling?.accounting?.paymentPlan ?? {};

  if (planType) {
    ({ paymentMethod } = getPaymentMethodDetails(planType.toLowerCase()));

    usingEasyPay = paymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY);
    usingMortgagee = paymentMethod.includes(PAYMENT_METHOD_CODE.INVOICE);
    usingDirectBill = paymentMethod.includes(PAYMENT_METHOD_CODE.DIRECTBILL);
  }

  return (
    <Card>
      <div className={styles.titleHeader}>
        <Heading size="md">Payment plan</Heading>
        <div className={styles.badge}>
          <Badge appearance="neutral">
            {usingEasyPay && "EASYPAY"}
            {usingDirectBill && "DIRECT BILL"}
            {usingMortgagee && "MORTGAGE"}
          </Badge>
        </div>
      </div>

      {usingMortgagee && <PaymentPlanMortgagee />}

      {(usingEasyPay || usingDirectBill) && (
        <PaymentPlanPolicyholderPayer
          userBillingEmailAddress={userBillingEmailAddress}
          policyPaymentPlans={policyPaymentPlans}
        />
      )}

      <div className={styles.settingsLink}>
        Manage billing information in{" "}
        <MSSLink to="/my/settings/payment">Settings</MSSLink>
      </div>
    </Card>
  );
};
