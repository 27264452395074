import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { Button, type ButtonProps } from "@icg360/design-system";

import { screenSizes } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import styles from "./property-update-buttons.module.scss";
import {
  PropertyUpdateRoofContext,
  PropertyUpdateRoofContextState,
} from "./property-update-roof";

type PropertyUpdateButtonsProps = {
  primaryDisabled?: boolean;
  primaryOnClick?: ButtonProps["onPress"];
  primaryPath?: string;
  primaryText?: string;
  step: number;
};

export const PropertyUpdateButtons = ({
  primaryDisabled,
  primaryOnClick,
  primaryPath,
  primaryText,
  step,
}: PropertyUpdateButtonsProps) => {
  const navigate = useNavigate();
  const { formNavigate } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );
  const isMobile = useMediaQuery({ maxWidth: screenSizes.tabletS });

  return (
    <footer className={styles.footer}>
      <div className={styles.navButtons}>
        <Button
          appearance="tertiary"
          data-testid="property-update-cancel-btn"
          fluid={!isMobile}
          onPress={() => navigate("/my/property")}
          size={isMobile ? "sm" : "default"}
        >
          Cancel
        </Button>
        <Button
          appearance="secondary"
          className={step === 1 && styles.hidden}
          data-testid="property-update-back-btn"
          disabled={step === 1}
          fluid={!isMobile}
          onPress={() => formNavigate(-1)}
          size={isMobile ? "sm" : "default"}
        >
          Back
        </Button>
        <Button
          data-testid="property-update-next-btn"
          disabled={primaryDisabled}
          fluid
          onPress={
            primaryOnClick ??
            (primaryPath ? () => formNavigate(primaryPath) : undefined)
          }
          size={isMobile ? "sm" : "default"}
        >
          {primaryText ?? "Next"}
        </Button>
      </div>
    </footer>
  );
};
