import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { NotificationName } from "components/shared/notifications/notifications";
import { CoverageContext } from "pages/overview/extended-coverages/hooks";
import { getCookie, useFlags } from "utils";

export const useShowHomeServicesAlert = (
  availableNotifications: NotificationName[]
) => {
  return availableNotifications.includes("HomeServices");
};

export const useShowCancellationPaymentNotice = (
  availableNotifications: NotificationName[]
) => {
  return availableNotifications.includes("CancellationPayment");
};

export const useShowCoverageNotification = (
  availableNotifications: NotificationName[]
) => {
  const { coveragePage } = useContext(CoverageContext);
  const { selectedPolicyId } = useContext(AuthAppContext);

  return (
    availableNotifications.includes("Coverage") &&
    coveragePage?.type &&
    selectedPolicyId === coveragePage.policyId
  );
};

export const useShowLeakBotBanner = (
  availableNotifications: NotificationName[]
) => {
  const { leakbotNyPromo } = useFlags();
  const { userDetails } = useContext(AuthAppContext);
  if (!availableNotifications.includes("Leakbot")) {
    return false;
  }
  const { currentTerm, isEnrolledInLeakBot } = userDetails ?? {};
  // show success banner one time
  const isNewlyEnrolled = getCookie(
    `mss-leakbot-newly-enrolled-${currentTerm?.policyNumber}`
  );
  return (
    userDetails && leakbotNyPromo && (!isEnrolledInLeakBot || isNewlyEnrolled)
  );
};
