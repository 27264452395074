import { number as cardNumberValidator } from "card-validator";
import { Field, useFormikContext } from "formik";

import { Alert, Button, Heading, Span } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";
import { CARD_EXPIRY_DATE_MASK } from "consts";
import AcceptedCards from "images/accepted-cards.svg";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";

import styles from "./card-info.module.scss";

type CardInfoProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const CardInfo = ({ setStep }: CardInfoProps) => {
  const { touched, errors } = useFormikContext<EasyPayFormValues>();
  const cardInfoTouched =
    touched?.cc?.cardholderName &&
    touched?.cc?.cardExpirationDate &&
    touched?.cc?.cardNumber &&
    touched?.cc?.cardZipCode;

  const cardInfoHasErrors =
    errors?.cc?.cardholderName ||
    errors?.cc?.cardExpirationDate ||
    errors?.cc?.cardNumber ||
    errors?.cc?.cardZipCode;

  const cardInfoIsValid = cardInfoTouched && !cardInfoHasErrors;

  return (
    <div className={styles.cardBody}>
      <Heading size="lg">Enter your card information</Heading>
      <Alert
        appearance="info"
        title=""
        description={
          <>
            Our payment processor charges a <Span bold>2.99%</Span> fee on
            credit card payments.
          </>
        }
      />
      <div>
        <div>Accepted Cards:</div>
        <AcceptedCards className={styles.acceptedCards} />
      </div>

      <CardInfoFields />

      <div className={styles.navigation}>
        <Button
          appearance="tertiary"
          onPress={() => setStep("chooseMethod")}
          data-testid="easypay-card-info-back-btn"
        >
          Go back
        </Button>
        <Button
          onPress={() => setStep("review")}
          disabled={!cardInfoIsValid}
          data-testid="easypay-card-info-continue-btn"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const CardInfoFields = () => {
  const { setFieldValue, values } = useFormikContext<EasyPayFormValues>();
  const handleCardNumber = (e) => {
    let creditCardType = "";
    const currentCardNumber = e.target.value;
    let formattedCardNumber = currentCardNumber;
    let startCursorPosition = e.target.selectionStart;

    if (currentCardNumber) {
      const userCard = cardNumberValidator(currentCardNumber);
      if (userCard.isValid) {
        creditCardType = userCard.card.niceType;
      }
      if (userCard.card && userCard.card.gaps.length > 0) {
        const rawCardNumber = currentCardNumber.replace(/-/g, "");
        let regExp = "",
          repExp = "";
        for (let index = 0; index < userCard.card.gaps.length; index++) {
          if (userCard.card.gaps[index] >= rawCardNumber.length) break;
          const arrayLength =
            index === 0
              ? userCard.card.gaps[index]
              : userCard.card.gaps[index] - userCard.card.gaps[index - 1];

          regExp += `(${new Array(arrayLength + 1).join(".")})`;
          repExp += `$${index + 1}-`;
        }

        formattedCardNumber =
          regExp === ""
            ? currentCardNumber
            : rawCardNumber.replace(new RegExp(regExp), repExp);

        if (formattedCardNumber !== currentCardNumber) {
          setFieldValue(e.target.name, formattedCardNumber);
        }
      }
    }
    if (creditCardType !== values.cc.creditCardType) {
      // This is super lame, but if you call multiple setFieldValue synchronically then it'll override the previous value set above
      setTimeout(() => setFieldValue("cc.creditCardType", creditCardType), 1);
    }

    e.target.value = formattedCardNumber;
    if (
      currentCardNumber.substring(0, startCursorPosition) !==
      formattedCardNumber.substring(0, startCursorPosition)
    )
      startCursorPosition++;
    e.target.setSelectionRange(startCursorPosition, startCursorPosition);
  };

  return (
    <>
      <Field
        name="cc.cardholderName"
        title="Name on card"
        component={InputField}
      />
      <Field
        name="cc.cardNumber"
        title="Card number"
        component={InputField}
        onChange={handleCardNumber}
      />
      <Field
        name="cc.cardExpirationDate"
        title="Expiration date"
        component={InputField}
        mask={CARD_EXPIRY_DATE_MASK}
        placeholder="MM/YYYY"
        className={styles.shortField}
      />
      <Field
        name="cc.cardZipCode"
        title="Card zip code"
        component={InputField}
        className={styles.shortField}
      />
    </>
  );
};
