import { useState } from "react";

import { Button, Link as DSLink, Heading } from "@icg360/design-system";

import { PROPERTY_ROOF_TYPES } from "consts";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";

import { PropertyUpdateRoofContext, PropertyUpdateRoofContextState } from ".";
import { PropertyUpdateLayout } from "../property-update-layout";
import RoofTypesModal from "../update-roof/roof-types-modal";
import styles from "./property-update-roof.module.scss";
import { RoofMaterialCard } from "./roof-material-card";

const INITIAL_ROOF_MATERIALS_SHOWN = 6;

export const RoofMaterial = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { state, setFields } =
    useMultiStepFormState<PropertyUpdateRoofContextState>(
      PropertyUpdateRoofContext
    );

  const displayedMaterials = showAll
    ? PROPERTY_ROOF_TYPES
    : PROPERTY_ROOF_TYPES.slice(0, INITIAL_ROOF_MATERIALS_SHOWN).concat(
        PROPERTY_ROOF_TYPES.slice(-1)
      );

  return (
    <PropertyUpdateLayout
      step={2}
      heading="Update roof details"
      buttonProps={{
        primaryDisabled: !state?.roofMaterial,
        primaryPath: "../proof",
      }}
    >
      <div>
        <Heading size="md">What&apos;s your roof made of?</Heading>
        <DSLink onPress={() => setModalOpen(true)} className={styles.modalLink}>
          Learn more about each type
        </DSLink>

        <div className={styles.roofMaterials}>
          {displayedMaterials.map((roofType) => (
            <RoofMaterialCard
              roofType={roofType}
              onClick={() => setFields({ roofMaterial: roofType })}
              selected={roofType === state?.roofMaterial}
              key={roofType}
            />
          ))}
        </div>
        <Button
          appearance="tertiary"
          onPress={() => setShowAll(!showAll)}
          className={styles.showMoreLink}
          trailingIcon={showAll ? "ArrowUp" : "ArrowDown"}
        >
          See {showAll ? "fewer" : "more"} roof materials
        </Button>
      </div>
      <RoofTypesModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </PropertyUpdateLayout>
  );
};
