import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@icg360/design-system";

import { useEnvelopeToSign } from "hooks/use-envelope-to-sign";
import {
  BadgeProps,
  WelcomeBannerSection,
} from "pages/overview/welcome-banner/welcome-banner-section";
import { trackEvent } from "utils";

const SignatureSection = () => {
  const navigate = useNavigate();
  const { envelopeToSign, loading } = useEnvelopeToSign();
  const [badgeProps, setBadgeProps] = useState<BadgeProps>();

  useEffect(() => {
    setBadgeProps(
      envelopeToSign ? { appearance: "warning", text: "Incomplete" } : undefined
    );
  }, [envelopeToSign]);

  const onGoToDocumentsClick = () => {
    trackEvent("welcomeTaskCard_eSign_goToDocuments", {
      hasDocumentsToSign: !!envelopeToSign,
    });
    navigate("/my/documents");
  };

  return (
    <WelcomeBannerSection
      title="eSignature required"
      badgeProps={badgeProps}
      footer={
        <Button
          appearance={envelopeToSign ? "primary" : "secondary"}
          size="sm"
          onPress={onGoToDocumentsClick}
        >
          Go to documents
        </Button>
      }
      loading={loading}
    >
      {envelopeToSign ? (
        <div>
          You have documents that require your electronic signature. You can
          find them on the Documents page.
        </div>
      ) : (
        <div>You have no documents to sign.</div>
      )}
    </WelcomeBannerSection>
  );
};

export default SignatureSection;
