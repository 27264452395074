import cx from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";

import styles from "./stack.module.scss";

type StackProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  gap?: "sm" | "lg" | "xl";
};

const Stack = ({ children, gap = "xl", className, ...props }: StackProps) => {
  return (
    <div
      className={cx(styles.stack, className, {
        [styles.gapSm]: gap === "sm",
        [styles.gapLg]: gap === "lg",
        [styles.gapXl]: gap === "xl",
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default Stack;
