import { ENROLLMENT_STATUS, PAYMENT_METHOD_CODE } from "consts";
import { CommonPolicyDataQuery } from "gql/__generated__/hooks.js";
import { getPaymentMethodDetails } from "utils";

export const getPaperlessEnrollment = (userBilling, userDetails) => {
  const {
    accounting: {
      paymentPlan: { planType },
    },
  } = userBilling;

  const { paymentMethod } = getPaymentMethodDetails(planType.toLowerCase());

  const {
    insuredPreferences: {
      paperlessBillingEnrollmentStatus,
      paperlessDocumentEnrollmentStatus,
    },
    isCommercial,
  } = userDetails;

  const isEnrolledDocuments =
    paperlessDocumentEnrollmentStatus === ENROLLMENT_STATUS.ENROLLED;

  const isEnrolledBilling =
    paperlessBillingEnrollmentStatus === ENROLLMENT_STATUS.ENROLLED ||
    paymentMethod === PAYMENT_METHOD_CODE.INVOICE ||
    paymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY) ||
    isEnrolledDocuments;

  //Ineligibile is distinct from not enrolled
  const isIneligible = isCommercial;

  return { isIneligible, isEnrolledDocuments, isEnrolledBilling };
};

export const isPaperlessDocumentEligible = (
  userDetails: CommonPolicyDataQuery["userDetails"]
): boolean => {
  if (userDetails) {
    const isPaperlessDocumentEnrolled =
      userDetails.insuredPreferences?.paperlessDocumentEnrollmentStatus ===
      ENROLLMENT_STATUS.ENROLLED;
    return !isPaperlessDocumentEnrolled && !userDetails.isCommercial;
  }
  return false;
};
