import { Heading, ProgressStep, ProgressStepper } from "@icg360/design-system";

import { PropertyUpdateButtons } from "./property-update-buttons";
import styles from "./property-update-layout.module.scss";

export const PropertyUpdateLayout = ({
  buttonProps,
  children,
  heading,
  step,
}) => {
  const steps: ProgressStep[] = [];
  for (let i = 1; i <= 4; i++) {
    if (i < step) {
      steps.push({ status: "completed" });
    } else if (i === step) {
      steps.push({ status: "in-progress" });
    } else {
      steps.push({ status: "incomplete" });
    }
  }

  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.layout}>
        <Heading size="sm" className={styles.mainHeading}>
          {heading}
        </Heading>
        <ProgressStepper appearance="simple" steps={steps} />
        <div className={styles.content}>{children}</div>
      </div>
      <PropertyUpdateButtons step={step} {...buttonProps} />
    </div>
  );
};
