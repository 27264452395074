import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import cloneDeep from "lodash.clonedeep";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { addToast } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import {
  ENROLLMENT_STATUS,
  PAYMENT_METHOD_CODE,
  achSchema,
  ccSchema,
} from "consts";
import {
  CommonPolicyDataQuery,
  useEasypayEnrollMutation,
  usePaperlessDocumentsEnrollMutation,
} from "gql/__generated__/hooks";
import { commonPolicyDataQuery } from "gql/queries";
import { useCurrentPaymentMethod } from "pages/payment-plan-update/easypay-enroll-form/hooks";
import {
  KeystoneApiClient,
  getPaymentMethodDetails,
  isError,
  isPaperlessDocumentEligible,
  logError,
  setCookie,
  trackEvent,
  urlSearchParamsObject,
  useFlags,
  useRefreshKeystoneAppData,
} from "utils";

import { EasypayEnrollForm } from "./easypay-enroll-form";

const SUCCESS_DURATION = 8000; // eight seconds

export type EasyPayFormValues = {
  paymentFrequency: "FullPay" | "TwoPay" | "FourPay" | "TenPay" | null;
  paymentMethod: "invoice" | "easypay" | "easypaycc" | "directbill";
  easypayMethod: "easypay" | "easypaycc";
  policyNumber: string;
  draftDate: "1stMonth" | "8thMonth" | "15thMonth" | "22ndMonth" | null;
  enrollDocuments: "yes" | "no";
  ach: {
    easypayMethod: "checking" | "savings";
    bankStatementName: string;
    financialInstitutionName: string;
    accountNumber: string;
    routingNumber: string;
    authorize: boolean;
  };
  cc: {
    authorize: boolean;
    cardholderName: string;
    cardNumber: string;
    cardExpirationDate: string;
    cardZipCode: string;
    creditCardType: string;
  };
};

const keystone = new KeystoneApiClient(CONFIG.KEYSTONE_API_HREF);

const PaymentPlanUpdate = () => {
  const navigate = useNavigate();
  const { easyPayFullPayOnly, retireProxy, universalLogin } = useFlags();
  const refreshKeystoneAppData = useRefreshKeystoneAppData();

  const {
    selectedPolicyId,
    userBilling,
    userInfo,
    userDetails,
    isPolicyDataReady,
  } = useContext(AuthAppContext);

  const [paperlessDocumentsEnroll] = usePaperlessDocumentsEnrollMutation();
  const [easypayEnrollMutation] = useEasypayEnrollMutation();

  const { eligibleForEasyPayUpdate, trackEasyPayEvent } =
    useCurrentPaymentMethod(userBilling, userDetails);

  const parsedQS = urlSearchParamsObject();

  if (!isPolicyDataReady) {
    return <BaseLayout hideNavigation loading />;
  }

  /*
    we need to check the updatepayment context values here to avoid triggering
    the redirect accidentally in the middle of the form submission process.
  */
  if (!eligibleForEasyPayUpdate) {
    return (
      <Navigate
        to={`/my/billing${parsedQS.origin ? `?origin=${parsedQS.origin}` : ""}`}
        replace
      />
    );
  }

  const validate = async (values) => {
    try {
      if (values.easypayMethod === PAYMENT_METHOD_CODE.EASYPAYCC) {
        await validateYupSchema(values.cc, ccSchema, true);
      } else {
        await validateYupSchema(values.ach, achSchema, true);
      }
    } catch (error) {
      if (values.easypayMethod === PAYMENT_METHOD_CODE.EASYPAYCC) {
        return { cc: yupToFormErrors(error) };
      } else {
        return { ach: yupToFormErrors(error) };
      }
    }
  };

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    let enrollSuccess = false;
    let enrollError: string | undefined;

    if (retireProxy && universalLogin) {
      const user = {
        firstName: userInfo?.firstName ?? "",
        lastName: userInfo?.lastName ?? "",
        email: userInfo?.email ?? "",
      };
      // TODO: once graphql path is removed, remove this field at the source
      delete values.enrollDocuments;
      const res = await keystone.enrollEasypay(values, user);
      if (isError(res)) {
        enrollError = res.message;
        logError(`Enroll in Easypay Keystone error: ${enrollError}`);
        setStatus("failed");
        trackEvent("EasyPay enrollment error caught", {
          errorMessage: enrollError,
        });
        setSubmitting(false);
      } else if (res.success) {
        enrollSuccess = true;
        refreshKeystoneAppData();
      }
    } else {
      try {
        const { data } = await easypayEnrollMutation({
          variables: { paymentData: values },
          update: (store, { data }) => {
            const { easypayEnroll } = data ?? {};
            if (easypayEnroll?.success) {
              const commonPolicyData = cloneDeep(
                store.readQuery<CommonPolicyDataQuery>({
                  query: commonPolicyDataQuery,
                  variables: { policyID: selectedPolicyId },
                })
              );

              if (commonPolicyData?.userDetails?.insuredPreferences) {
                commonPolicyData.userDetails.insuredPreferences = {
                  ...commonPolicyData.userDetails.insuredPreferences,
                  ...easypayEnroll.data,
                };

                store.writeQuery({
                  query: commonPolicyDataQuery,
                  variables: { policyID: selectedPolicyId },
                  data: commonPolicyData,
                });
              }
            }
          },
        });
        enrollSuccess = !!data?.easypayEnroll.success;
        if (data?.easypayEnroll.errors) {
          enrollError = data?.easypayEnroll.errors?.[0];
        }
      } catch (err) {
        enrollError = err;
        logError(`Enroll in Easypay GraphQL error: ${err.message}`);
        setStatus("failed");
        trackEvent("EasyPay enrollment error caught", {
          errorMessage: err,
        });
        setSubmitting(false);
      }
    }

    const { paymentMethodSummaryLabel } = getPaymentMethodDetails(
      userBilling?.accounting?.paymentPlan?.planType?.toLowerCase()
    );
    const { draftDate, easypayMethod, enrollDocuments, paymentFrequency } =
      values;
    const enrollDocumentsBool = enrollDocuments === "yes";

    if (enrollSuccess) {
      setStatus("enrolled");

      // Users enrolled in easypay form are also enrolled in paperless billing and won't need paperless modal
      setCookie("mss-paperless-modal-dismissed", true);

      if (enrollDocumentsBool && isPaperlessDocumentEligible(userDetails)) {
        await paperlessDocumentsEnroll({
          variables: { policyID: selectedPolicyId },
          update: (store, { data: successful }) => {
            if (successful) {
              const readData = cloneDeep(
                store.readQuery<CommonPolicyDataQuery>({
                  query: commonPolicyDataQuery,
                  variables: { policyID: selectedPolicyId },
                })
              );

              if (readData?.userDetails?.insuredPreferences) {
                readData.userDetails.insuredPreferences.paperlessDocumentEnrollmentStatus =
                  ENROLLMENT_STATUS.ENROLLED;

                store.writeQuery({
                  query: commonPolicyDataQuery,
                  data: readData,
                  variables: { policyID: selectedPolicyId },
                });
              }
            }
          },
        });
      }

      trackEvent("EasyPay enrolled", {
        paymentMethod: paymentMethodSummaryLabel,
        easypayMethod: easypayMethod.includes("cc") ? "debit-credit" : "ach",
        paymentFrequency: paymentFrequency,
        paperlessOptIn: enrollDocumentsBool,
        paymentProcessingDay: draftDate.includes("15th")
          ? "fifteenth"
          : "first",
      });

      addToast(
        "Thanks for enrolling in EasyPay! It may take a few minutes for these changes to show up.",
        { icon: true, duration: SUCCESS_DURATION }
      );
      navigate("/my/billing");
    } else {
      setStatus("failed");
      trackEvent("EasyPay enrollment error", {
        errorMessage: enrollError,
      });
    }

    setSubmitting(false);
  };

  const initialValues: EasyPayFormValues = {
    paymentFrequency: easyPayFullPayOnly ? "FullPay" : null,
    paymentMethod: "easypay",
    easypayMethod: "easypay",
    policyNumber: selectedPolicyId,
    draftDate: null,
    enrollDocuments: "yes",
    ach: {
      easypayMethod: "checking",
      bankStatementName: "",
      financialInstitutionName: "",
      accountNumber: "",
      routingNumber: "",
      authorize: false,
    },
    cc: {
      authorize: false,
      cardholderName: "",
      cardNumber: "",
      cardExpirationDate: "",
      cardZipCode: "",
      creditCardType: "",
    },
  };

  return (
    <BaseLayout hideNavigation>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
      >
        <Form>
          <EasypayEnrollForm trackEasyPayEvent={trackEasyPayEvent} />
        </Form>
      </Formik>
    </BaseLayout>
  );
};

export default PaymentPlanUpdate;
