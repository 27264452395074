import { ENROLLMENT_STATUS, PAYMENT_METHOD_CODE } from "consts";
import { CommonPolicyDataQuery } from "gql/__generated__/hooks";
import { getPaymentMethodDetails } from "utils";

export const isEasyPayEnrolled = (
  userBilling: CommonPolicyDataQuery["userBilling"]
): boolean => {
  if (userBilling) {
    const { paymentMethod } = getPaymentMethodDetails(
      userBilling.accounting?.paymentPlan?.planType?.toLowerCase()
    );

    return paymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY);
  }
  return false;
};

export const isEasyPayPending = (
  userDetails: CommonPolicyDataQuery["userDetails"]
): boolean => {
  if (userDetails) {
    return (
      userDetails.insuredPreferences?.easyPayEnrollmentStatus ===
      ENROLLMENT_STATUS.PENDING
    );
  }
  return false;
};

export const isInvoiceMortgagee = (
  userBilling: CommonPolicyDataQuery["userBilling"]
): boolean => {
  if (userBilling) {
    const { paymentMethod } = getPaymentMethodDetails(
      userBilling.accounting?.paymentPlan?.planType?.toLowerCase()
    );

    return paymentMethod.includes(PAYMENT_METHOD_CODE.INVOICE);
  }
  return false;
};

export const isEasyPayEligible = (
  userBilling: CommonPolicyDataQuery["userBilling"],
  userDetails: CommonPolicyDataQuery["userDetails"]
): boolean => {
  if (userBilling && userDetails) {
    const isEnrolledInEasyPay = isEasyPayEnrolled(userBilling);
    const isPendingEasyPayEnrollment = isEasyPayPending(userDetails);
    const isInvoiced = isInvoiceMortgagee(userBilling);
    // Only allows active policies for paperless/easypay; this may change.
    const isActivePolicy = userDetails.policyStatus === "ACTIVEPOLICY";
    return (
      !isEnrolledInEasyPay &&
      !isInvoiced &&
      !isPendingEasyPayEnrollment &&
      isActivePolicy
    );
  }

  return false;
};

export const isEasyPayEnrolledOrPending = (
  userBilling: CommonPolicyDataQuery["userBilling"],
  userDetails: CommonPolicyDataQuery["userDetails"]
): boolean => {
  if (userBilling && userDetails) {
    return !isEasyPayEligible(userBilling, userDetails);
  }

  return false;
};
