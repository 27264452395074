import { PropsWithChildren } from "react";

import { Button } from "@icg360/design-system";

import { usePaymentButton } from "components/payment-button/hooks";
import { type PaymentConfig } from "utils";

type PaymentButtonProps = PropsWithChildren<{
  paymentConfig?: PaymentConfig;
  onPaymentError?: (error: Error) => void;
  onPaymentCanceled?: () => void;
  onPaymentComplete?: (response) => void;
  onPaymentStart?: () => void;
  onScheduledPaymentCreated?: (response) => void;
}>;

export const PaymentButton = ({
  paymentConfig,
  onPaymentStart,
  onPaymentCanceled,
  onPaymentComplete,
  onPaymentError,
  onScheduledPaymentCreated,
  children,
}: PaymentButtonProps) => {
  const { oneIncPortalReady, loading, onOpenPayment } = usePaymentButton({
    paymentConfig,
    onPaymentStart,
    onPaymentCanceled,
    onPaymentComplete,
    onPaymentError,
    onScheduledPaymentCreated,
  });

  return (
    <Button
      disabled={!oneIncPortalReady}
      loading={loading}
      onPress={onOpenPayment}
    >
      {children}
    </Button>
  );
};
