import cx from "classnames";
import { ComponentProps } from "react";

import { Heading } from "@icg360/design-system";

import styles from "./table.module.scss";

export const Table = ({
  children,
  className,
  ...props
}: ComponentProps<"table">) => {
  return (
    <table className={cx(styles.table, className)} {...props}>
      {children}
    </table>
  );
};

export const TableRow = ({
  children,
  className,
  ...props
}: ComponentProps<"tr">) => {
  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  );
};

export const TableCell = ({
  children,
  className,
  align,
  ...props
}: ComponentProps<"td">) => (
  <td className={cx(className, align && styles[align])} {...props}>
    {children}
  </td>
);

export const TableHeader = ({
  children,
  className,
  align,
  ...props
}: ComponentProps<"th">) => (
  <th className={cx(className, align && styles[align])} {...props}>
    <Heading size="xs">{children}</Heading>
  </th>
);

export const TableHead = ({
  children,
  className,
  ...props
}: ComponentProps<"thead">) => (
  <thead className={className} {...props}>
    {children}
  </thead>
);

export const TableBody = ({
  children,
  className,
  ...props
}: ComponentProps<"tbody">) => (
  <tbody className={className} {...props}>
    {children}
  </tbody>
);
