import { Form, useFormikContext } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Card, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { Stack } from "components/common/stack";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";

import { SecurityFormValues } from ".";
import styles from "./update-security.module.scss";

export const ReviewSecurity = () => {
  const navigate = useNavigate();
  const {
    isSubmitting,
    status,
    values: { burglarAlarm, fireAlarm },
  } = useFormikContext<SecurityFormValues>();

  useEffect(() => {
    if (!burglarAlarm || !fireAlarm) {
      navigate("/my/property/security");
    }
  }, [burglarAlarm, fireAlarm, navigate]);

  return (
    <>
      <ProgressBar progress={90} />
      <MainLayout
        sidebar={
          <PropertyProfileSidebar
            headline="Next steps"
            body={
              <p>
                If you are adding a new <b>central alarm system</b>, you will
                receive an email from SageSure requesting the{" "}
                <b>alarm certificate</b> after you submit this update.
                <br />
                <br />
                If you are adding a local alarm system or removing an alarm
                system, there are no further steps needed.
              </p>
            }
          />
        }
      >
        <Card className={styles.reviewCardDS}>
          <Form>
            <Stack>
              <Heading size="lg">Please review your updates</Heading>

              {status?.type === "error" && (
                <Alert
                  appearance="danger"
                  title=""
                  description="There was an error. Please try again or contact support if this persists."
                />
              )}
              <Heading size="sm">Security</Heading>

              <Body>
                Alarm systems can protect your home and property, and may even
                lower your insurance premium.
              </Body>

              <InlineDataRow className={styles.dataRow}>
                <DataTerm>Burglar alarm:</DataTerm>
                <DataDefinition>{burglarAlarm}</DataDefinition>
              </InlineDataRow>

              <InlineDataRow className={styles.dataRow}>
                <DataTerm>Fire alarm:</DataTerm>
                <DataDefinition>{fireAlarm}</DataDefinition>
              </InlineDataRow>

              <div className={styles.navigation}>
                <Button
                  appearance="tertiary"
                  onPress={() => navigate("/my/property/security")}
                >
                  Go back
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || !fireAlarm || !burglarAlarm}
                  loading={isSubmitting}
                  data-testid="security-submit-btn"
                >
                  Submit updates
                </Button>
              </div>
            </Stack>
          </Form>
        </Card>
      </MainLayout>
    </>
  );
};
