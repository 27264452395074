import { Field, useFormikContext } from "formik";

import { Button, Heading, Icon, Span, Tooltip } from "@icg360/design-system";

import {
  InputField,
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import CheckImage from "images/check-tooltip.svg";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";

import styles from "./bank-info.module.scss";

type BankInfoProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const BankInfo = ({ setStep }: BankInfoProps) => {
  const { touched, errors } = useFormikContext<EasyPayFormValues>();
  const bankInfoTouched =
    touched?.ach?.bankStatementName &&
    touched?.ach?.financialInstitutionName &&
    touched?.ach?.routingNumber &&
    touched?.ach?.accountNumber;

  const bankInfoHasErrors =
    errors?.ach?.bankStatementName ||
    errors?.ach?.financialInstitutionName ||
    errors?.ach?.routingNumber ||
    errors?.ach?.accountNumber;

  const bankInfoIsValid = bankInfoTouched && !bankInfoHasErrors;

  return (
    <div className={styles.wrapper}>
      <Heading size="lg">Enter your bank information</Heading>
      <BankInfoFields />

      <div className={styles.navigation}>
        <Button
          appearance="tertiary"
          onPress={() => setStep("chooseMethod")}
          data-testid="easypay-bank-info-back-btn"
        >
          Go back
        </Button>
        <Button
          onPress={() => setStep("review")}
          disabled={!bankInfoIsValid}
          data-testid="easypay-bank-info-continue-btn"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const BankInfoFields = () => {
  const { values, setFieldValue } = useFormikContext<EasyPayFormValues>();
  const CheckTooltip = () => (
    <Tooltip
      content={
        <>
          <span>
            You can find your bank account and routing numbers on your
            bank&apos;s website or app, or find them on a check as shown below:
          </span>
          <CheckImage />
        </>
      }
    >
      <Span color="quiet">
        <Icon name="Information" size="sm" />
      </Span>
    </Tooltip>
  );

  return (
    <>
      <Field
        component={RadioButtonGroup}
        onChange={(value) => {
          setFieldValue("ach.easypayMethod", value);
        }}
        label="Account type"
        value={values.ach.easypayMethod}
        fieldChildren={() => (
          <>
            <Field
              label="Checking"
              value="checking"
              component={RadioButtonField}
            />
            <Field
              label="Savings"
              value="savings"
              component={RadioButtonField}
            />
          </>
        )}
        inline
      />
      <Field
        name={"ach.bankStatementName"}
        title="Name on account"
        component={InputField}
      />
      <Field
        name={"ach.financialInstitutionName"}
        title="Name of bank"
        component={InputField}
      />
      <Field
        name={"ach.routingNumber"}
        title={
          <div className={styles.labelWithTooltip}>
            Routing number
            <CheckTooltip />
          </div>
        }
        component={InputField}
      />
      <Field
        name={"ach.accountNumber"}
        title={
          <div className={styles.labelWithTooltip}>
            Account number
            <CheckTooltip />
          </div>
        }
        component={InputField}
      />
    </>
  );
};
