import {
  PAYMENT_METHODS,
  PAYMENT_METHOD_CODE,
  PAYMENT_METHOD_DETAILS,
  PAYMENT_PLANS_INFO,
} from "consts";

import { formatCurrency } from "./format-currency";

const isNil = (value) => {
  return value == null;
};

export const getPageName = (pathname) => {
  switch (pathname) {
    case "/my/overview":
      return "overview";
    case "/my/billing":
      return "billing";
    case "/my/billing/update":
      return "billing";
    case "/my/documents":
      return "documents";
    case "/my/claims":
    case "/my/claims/submit":
      return "claims";
    case "/my/support":
      return "support";
    case "/my/easypay_confirmation":
      return "billing";
    case "/my/leakbot":
      return "leakbot";
    case "/my/property":
      return "property";
    default:
      if (pathname.includes("/my/property/")) {
        return "propertyDetails";
      }
      if (pathname.includes("/my/settings")) {
        return "settings";
      }
      return "";
  }
};

export const getFrequencyName = (frequency) => {
  switch (frequency) {
    case "1":
      return "FullPay";
    case "2":
      return "TwoPay";
    case "4":
      return "FourPay";
    case "10":
      return "TenPay";
    default:
      return;
  }
};

export const getOtherPaymentText = (frequency) => {
  switch (frequency) {
    case "FullPay":
      return null;
    case "TwoPay":
      return "Payment 2";
    case "FourPay":
      return "Payments 2-4";
    case "TenPay":
      return "Payments 2-10";
    default:
      return;
  }
};

export const getOtherPaymentValue = (value, frequency) => {
  if (frequency !== "FullPay" && frequency !== "TwoPay") {
    return `${value} each`;
  }
  return value;
};

export const getPaymentInfoDisplay = ({
  paymentMethod,
  paymentFrequency,
  firstPayment,
  otherPayments,
  serviceFees,
}) => {
  const { frequencyLabel } = PAYMENT_PLANS_INFO.find(
    ({ frequency }) => frequency === paymentFrequency
  );
  return {
    paymentMethodDisplay: PAYMENT_METHODS[paymentMethod],
    paymentFrequencyDisplay: frequencyLabel,
    firstPaymentDisplay: isNaN(firstPayment)
      ? firstPayment
      : formatCurrency(firstPayment, true),
    otherPaymentsDisplay: isNaN(otherPayments)
      ? otherPayments
      : formatCurrency(otherPayments, true),
    serviceFeesDisplay: isNaN(serviceFees)
      ? serviceFees
      : formatCurrency(serviceFees, true),
  };
};

export const getBillingAddress = (
  planType,
  mortgageeAddress,
  mailingAddress
) => {
  if (
    !isNil(mortgageeAddress) &&
    planType.toLowerCase() === PAYMENT_METHOD_CODE.INVOICE
  ) {
    return {
      line1: mortgageeAddress.name,
      line2: mortgageeAddress.street1,
      line3: mortgageeAddress.street2,
      line4: `${mortgageeAddress.city}, ${mortgageeAddress.state} ${mortgageeAddress.zip}`,
    };
  } else if (!isNil(mailingAddress)) {
    return {
      line1: mailingAddress.name,
      line2: mailingAddress.street1,
      line3: mailingAddress.street2,
      line4: `${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.zip}`,
    };
  } else {
    return {
      line1: "",
      line2: "",
      line3: "",
      line4: "",
    };
  }
};

export const getBillingAddressRaw = (
  planType,
  mortgageeAddress,
  mailingAddress
) => {
  if (
    !isNil(mortgageeAddress) &&
    planType.toLowerCase() === PAYMENT_METHOD_CODE.INVOICE
  ) {
    return {
      billingCity: mortgageeAddress.city,
      billingState: mortgageeAddress.state,
      billingZip: `${mortgageeAddress.zip}`,
      billingStreet: `${mortgageeAddress.street1} ${mortgageeAddress.street2}`,
    };
  } else if (!isNil(mailingAddress)) {
    return {
      billingCity: mailingAddress.city,
      billingState: mailingAddress.state,
      billingZip: `${mailingAddress.zip}`,
      billingStreet: `${mailingAddress.street1} ${mailingAddress.street2}`,
    };
  } else {
    return {
      billingCity: "",
      billingState: "",
      billingZip: "",
      billingStreet: "",
    };
  }
};

export const getKeyDocs = (userDocuments, hasMortgagee) => {
  if (!userDocuments) {
    return [];
  }
  const {
    latest: { declaration, invoice },
    grouped,
  } = userDocuments;

  const policyDocs = grouped?.find(
    ([{ group }]) => group === "Policy Endorsements"
  );

  const policyDoc = policyDocs?.find(({ label }) =>
    hasMortgagee
      ? /.*Policy.*Endorse.*Mortgagee.*/.test(label)
      : /.*Policy.*Endorse.*Insured.*/.test(label)
  );

  return [
    !isNil(declaration) &&
      Object.assign({ ...declaration }, { name: "policyDeclarations" }),
    !isNil(invoice) &&
      Object.assign({ ...invoice }, { name: PAYMENT_METHOD_CODE.INVOICE }),
    !isNil(policyDoc) &&
      Object.assign({ ...policyDoc }, { name: "policyPackage" }),
  ].filter(Boolean);
};

export const getPaymentMethodDetails = (plan) => {
  return PAYMENT_METHOD_DETAILS[plan];
};
